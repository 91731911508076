import ControlledVideo from '../common/ControlledVideo';
import React, { useEffect } from 'react';
import MarbleverseBubbles from './MarbleverseBubbles';
import MarbleverseFeature from './MarbleverseFeature';
import MarbleverseIntro from './MarbleverseIntro';
import MarbleverseUseCase from './MarbleverseUseCase';
import { useTranslation } from 'react-i18next';
import lax from 'lax.js';
import { totalLength } from './timeline.variables';
import marbleverseIntroTimeline from './MarbleverseIntro.timeline';
import marbleverseUseCasePostTimeline from './MarbleverseUseCase.post.timeline';
import marbleverseUseCaseDiscoverTimeline from './MarbleverseUseCase.discover.timeline';
import marbleverseUseCaseToursTimeline from './MarbleverseUseCase.tours.timeline';
import marbleverseUseDropTimeline from './MarbleverseUseCase.drop.timeline';
import marbleverseFeatureGeoBasedTimeline from './MarbleverseFeature.geobased.timeline';
import marbleverseFeatureAugmentedRealityTimeline from './MarbleverseFeature.augmentedreality.timeline';
import marbleverseFeatureEveryoneTimeline from './MarbleverseFeature.everyone.timeline';
import marbleverseInfoMarbleTimeline from './MarbleverseInfoMarble.timeline';
import marbleverseCallToActionTimeline from './MarbleverseCallToAction.timeline';
import MarbleverseInfoMarble from './MarbleverseInfoMarble';
import MarbleverseCallToAction from './MarbleverseCallToAction';

const cdn = 'https://d370ydiedxu8ib.cloudfront.net';
const animatedBubbles = false;
const videoBubbles = true;

const timeline = [
  ...marbleverseIntroTimeline,
  ...marbleverseUseCasePostTimeline,
  ...marbleverseUseCaseDiscoverTimeline,
  ...marbleverseUseCaseToursTimeline,
  ...marbleverseUseDropTimeline,
  ...marbleverseFeatureGeoBasedTimeline,
  ...marbleverseFeatureAugmentedRealityTimeline,
  ...marbleverseFeatureEveryoneTimeline,
  ...marbleverseInfoMarbleTimeline,
  ...marbleverseCallToActionTimeline
];

window.lax = { presets: lax.presets };

let initialized = false;

const Marbleverse = ({ full }) => {
  // const beforePageChange = number => console.log(number);
  const $t = useTranslation().t;
  useEffect(() => {
    if (initialized) return;
    initialized = true;
    lax.init();
    lax.addDriver('scrollY', () => window.scrollY);
    timeline.forEach(e => lax.addElements(e.selector, e.transforms, { onUpdate: e.onUpdate }));
  }, [full]);
  return (
    <div className="Marbleverse">
      <MarbleverseBubbles cdn={cdn} animated={animatedBubbles} video={videoBubbles} />
      <MarbleverseIntro
        cdn={cdn}
        animatedBubbles={animatedBubbles}
        videoBubbles={videoBubbles}
        full={full}
      />
      {full && (
        <>
          <MarbleverseUseCase
            name="post"
            caption={$t('MarbleverseUseCase.postVideo')}
            background="marbleverse/background.post.videos.jpg"
            className="marble-pink"
            marbles="marbles/marbleverse.pink"
            content="marbleverse/editor.post.videos.png"
            video="marbleverse/post.video.mp4"
            tap
            cdn={cdn}
          />
          <MarbleverseUseCase
            name="discover"
            className="marble-pink"
            caption={$t('MarbleverseUseCase.discoverMemories')}
            captionVariant="dark"
            background="marbleverse/background.discover.memories.jpg"
            marbles="marbles/marbleverse.pink"
            useFallbackMarbles
            content="marbleverse/editor.discover.memories.jpg"
            tap
            cdn={cdn}
          />
          <MarbleverseUseCase
            name="tours-graffiti"
            className="marble-blue"
            caption={$t('MarbleverseUseCase.createTours')}
            background="marbleverse/background.create.tours.graffiti.jpg"
            marbles="marbles/marbleverse.blue"
            useFallbackMarbles
            content="marbleverse/editor.create.tours.graffiti.jpg"
            tap
            cdn={cdn}
          />
          <MarbleverseUseCase
            name="tours-food"
            caption={$t('MarbleverseUseCase.createTours')}
            background="marbleverse/background.create.tours.food.jpg"
            content="marbleverse/editor.create.tours.food.jpg"
            cdn={cdn}
          />
          <MarbleverseUseCase
            name="tours-truecrime"
            caption={$t('MarbleverseUseCase.createTours')}
            background="marbleverse/background.create.tours.truecrime.jpg"
            content="marbleverse/editor.create.tours.truecrime.jpg"
            cdn={cdn}
          />
          <MarbleverseUseCase
            name="drop"
            className="marble-red"
            caption={$t('MarbleverseUseCase.dropNFTs')}
            background="marbleverse/background.drop.nfts.jpg"
            marbles="marbles/marbleverse.red"
            useFallbackMarbles
            content="marbleverse/editor.drop.nfts.jpg"
            tap
            cdn={cdn}
          />
          <MarbleverseFeature
            name="geobased"
            caption={$t('MarbleverseFeature.geoBased.caption')}
            descriptions={[$t('MarbleverseFeature.geoBased.description')]}
            video="marbleverse/earth"
            playbackRate={1}
            cdn={cdn}
          />
          <MarbleverseFeature
            name="augmentedreality"
            className="marble-blue"
            caption={$t('MarbleverseFeature.augmentedReality.caption')}
            descriptions={[$t('MarbleverseFeature.augmentedReality.description')]}
            marbles="marbles/marbleverse.blue"
            useFallbackMarbles
            content="marbleverse/editor.feature.augmentedreality.jpg"
            tap
            cdn={cdn}
          />
          <MarbleverseFeature
            name="everyone"
            className="marble-red"
            caption={$t('MarbleverseFeature.everyone.caption')}
            descriptions={[
              $t('MarbleverseFeature.everyone.description.first'),
              $t('MarbleverseFeature.everyone.description.second'),
              $t('MarbleverseFeature.everyone.description.third')
            ]}
            visual={
              <div className="visual-everyone">
                <ControlledVideo
                  name="everyone"
                  type="marbles"
                  className="marbles marble-red"
                  video="marbles/marbleverse.red"
                  useFallbackMarbles
                  cdn={cdn}
                />
                <img
                  alt={$t('Common.visual')}
                  className="user"
                  src="/images/marbleverse/feature.everyone.user.png"
                />
              </div>
            }
            cdn={cdn}
          />
          <MarbleverseInfoMarble
            name="bandsintown"
            className="marble-bandsintown"
            marbles="marbles/marbleverse.bandsintown"
            useFallbackMarbles
            content="marbleverse/editor.infomarbles.bandsintown.jpg"
            caption={$t('MarbleverseInfoMarble.caption')}
            description={$t('MarbleverseInfoMarble.bandsintown')}
            cdn={cdn}
          />
          <MarbleverseInfoMarble
            name="metro"
            className="marble-metro"
            marbles="marbles/marbleverse.metro"
            useFallbackMarbles
            content="marbleverse/editor.infomarbles.metro.jpg"
            caption={$t('MarbleverseInfoMarble.caption')}
            description={$t('MarbleverseInfoMarble.metro')}
            cdn={cdn}
          />
          <MarbleverseInfoMarble
            name="wikipedia"
            className="marble-wikipedia"
            marbles="marbles/marbleverse.wikipedia"
            useFallbackMarbles
            content="marbleverse/editor.infomarbles.wikipedia.jpg"
            caption={$t('MarbleverseInfoMarble.caption')}
            description={$t('MarbleverseInfoMarble.wikipedia')}
            cdn={cdn}
          />
          <MarbleverseCallToAction />
          <div
            className="fake-scroll"
            style={{ paddingTop: `calc(600vh + ${totalLength - 1000}px)` }}
          >
            <div id="signup-anchor" />
          </div>
        </>
      )}
    </div>
  );
};

export default Marbleverse;
