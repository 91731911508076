import {
  arLength,
  discoverLength,
  dropLength,
  everyoneLength,
  geoBasedLength,
  introLength,
  postLength,
  toursLength
} from './timeline.variables';
import onUpdateFactory from '../../lib/onUpdateFactory';

const selector = '.MarbleverseFeature.everyone';

const startLength =
  introLength +
  postLength +
  discoverLength +
  toursLength +
  dropLength +
  geoBasedLength +
  arLength +
  500;
const endLength = startLength + everyoneLength;

const showLength = startLength + 6 * window.innerHeight;
const hideLength = endLength + 6 * window.innerHeight + 300;

const shown = false;

const timeline = [
  {
    selector,
    transforms: {
      scrollY: {
        translateY: [
          [`6*screenHeight+${startLength - 1}`, `6*screenHeight+${startLength}`],
          ['-screenHeight', 0]
        ]
      }
    },
    onUpdate: onUpdateFactory({ shown, showLength, hideLength })
  },
  {
    selector: `${selector} .infobox`,
    transforms: {
      scrollY: {
        opacity: [
          [
            `6*screenHeight+${startLength - 1}`,
            `6*screenHeight+${startLength}`,
            `6*screenHeight+${endLength}`,
            `6*screenHeight+${endLength + 300}`
          ],
          [0, 1, 1, 0]
        ]
      }
    }
  },
  {
    selector: `${selector} .infobox .caption .text`,
    transforms: {
      scrollY: {
        opacity: [
          [`6*screenHeight+${startLength}`, `6*screenHeight+${startLength}+200`],
          [0, 1]
        ]
      }
    }
  },
  {
    selector: `${selector} svg`,
    transforms: {
      scrollY: {
        scaleX: [
          [`6*screenHeight+${startLength}+100`, `6*screenHeight+${startLength + 500}`],
          [0, 0.99]
        ]
      }
    }
  },
  {
    selector: `${selector} .infobox .description`,
    transforms: {
      scrollY: {
        opacity: [
          [`6*screenHeight+${startLength + 100}`, `6*screenHeight+${startLength + 500}`],
          [0, 1]
        ]
      }
    }
  },
  {
    selector: `${selector} .visual`,
    transforms: {
      scrollY: {
        translateX: [
          [
            `6*screenHeight+${startLength}-1`,
            `6*screenHeight+${startLength}`,
            `6*screenHeight+${startLength + 300}`,
            `6*screenHeight+${endLength}`,
            `6*screenHeight+${endLength + 300}`
          ],
          ['2*screenWidth', 'screenWidth', 0, 0, 'screenWidth']
        ]
      }
    }
  }
];

export default timeline;
