import classnames from 'classnames';
import React from 'react';
import Phone from '../common/Phone';

const MarbleverseInfoMarble = props => {
  const {
    cdn,
    name,
    background,
    caption,
    className,
    description,
    marbles,
    useFallbackMarbles,
    content
  } = props;
  const showPhone = !!marbles || !!content;
  const backgroundImage = background ? `url(${cdn}/images/${background})` : undefined;
  return (
    <div className={classnames('MarbleverseInfoMarble', name, 'page', className)}>
      <div className="background" style={{ backgroundImage }}>
        <div className="content">
          <div className="caption">{caption}</div>
          <div className="info-marble">
            <div className="description">{description}</div>
            <div className="marble">
              {showPhone && (
                <Phone
                  cdn={cdn}
                  name={name}
                  marbles={marbles}
                  useFallbackMarbles={useFallbackMarbles}
                  content={content}
                  tap
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarbleverseInfoMarble;
