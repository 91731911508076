import React from 'react';

const CreatorsNavbar = ({ cdn, onSignUp, onSignIn, pricing }) => {
  const scrollTo = id => {
    const element = document.getElementById(id);
    if (element) element.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className="CreatorsNavbar nav navbar">
      <img alt="logo" className="logo" src={`${cdn}/images/creators/marble.logo.white.svg`} />
      <div className="nav-tabs">
        <div onClick={() => scrollTo('benefits')}>Benefits</div>
        <div onClick={() => scrollTo('features')}>Features</div>
        {pricing && <div onClick={() => scrollTo('pricing')}>Pricing</div>}
        <div onClick={() => scrollTo('network')}>Network</div>
      </div>
      <div className="app-access">
        <div className="sign-in" onClick={onSignIn}>
          Sign In
        </div>
        <div className="sign-up" onClick={onSignUp}>
          Sign Up
        </div>
      </div>
    </div>
  );
};

export default CreatorsNavbar;
