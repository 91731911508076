import { introLength } from './timeline.variables';
import onUpdateFactory from '../../lib/onUpdateFactory';

const selector = '.MarbleverseIntro';

const startLength = 0;
const endLength = startLength;

const showLength = startLength - 500;
const hideLength = endLength + window.innerHeight;
const shown = false;

const timeline = [
  {
    selector,
    transforms: {
      scrollY: {
        translateY: [
          [introLength, `${introLength}+screenHeight`],
          [0, '-screenHeight']
        ]
      }
    },
    onUpdate: onUpdateFactory({ shown, showLength, hideLength })
  },
  {
    selector: `${selector} .ScrollHint`,
    transforms: {
      scrollY: {
        opacity: [
          [introLength, `${introLength}+300`],
          [1, 0]
        ]
      }
    }
  }
];

export default timeline;
