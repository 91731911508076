import React from 'react';

class CarouselPrevButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(event) {
    event.preventDefault();
    this.props.previousSlide();
  }
  render() {
    const { disabled, hidden, currentSlide, wrapAround, slideCount } = this.props;
    const effectiveHidden = hidden || (currentSlide <= 0 && !wrapAround) || slideCount === 0;
    return (
      <button
        disabled={disabled}
        hidden={effectiveHidden}
        onClick={this.handleClick}
        aria-label="previous"
        type="button"
      >
        <i className={`fa fa-chevron-left`} />
      </button>
    );
  }
}

export default CarouselPrevButton;
