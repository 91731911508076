import React from 'react';

class CarouselNextButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.nextButtonDisable = this.nextButtonDisabled.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    this.props.nextSlide();
  }

  nextButtonDisabled(props) {
    const { disabled, wrapAround, slidesToShow, currentSlide, cellAlign, slideCount } = props;
    if (disabled) return true;
    if (wrapAround) return false;
    let buttonDisabled = false;
    const lastSlideIndex = slideCount - 1;
    let slidesShowing = slidesToShow;
    let lastSlideOffset = 0;
    switch (cellAlign) {
      case 'center':
        slidesShowing = (slidesToShow - 1) * 0.5;
        lastSlideOffset = Math.floor(slidesToShow * 0.5) - 1;
        break;
      case 'right':
        slidesShowing = 1;
        break;
      default:
    }
    if (slidesToShow > 1) {
      buttonDisabled = currentSlide + slidesShowing > lastSlideIndex + lastSlideOffset;
    } else {
      buttonDisabled = currentSlide + 1 > lastSlideIndex;
    }
    return buttonDisabled;
  }

  render() {
    const {
      disabled,
      hidden,
      wrapAround,
      slidesToShow,
      currentSlide,
      cellAlign,
      slideCount
    } = this.props;
    const effectiveDisabled = this.nextButtonDisabled({
      disabled,
      wrapAround,
      slidesToShow,
      currentSlide,
      cellAlign,
      slideCount
    });
    const effectiveHidden = hidden || currentSlide < 0 || currentSlide === slideCount - 1;
    return (
      <button
        disabled={effectiveDisabled}
        hidden={effectiveHidden}
        onClick={this.handleClick}
        aria-label="next"
        type="button"
      >
        <i className={`fa fa-chevron-right`} />
      </button>
    );
  }
}

export default CarouselNextButton;
