import React from 'react';
import Phone from '../common/Phone';
import ScrollHint from '../common/ScrollHint';

const NftIntro = () => {
  const onSignUp = () => {
    const anchor = document.getElementById('signup-anchor');
    anchor && anchor.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className="NftIntro">
      <div
        className="hero-banner"
        style={{ backgroundImage: 'url(/images/nft/constantin-building.compressed.jpeg)' }}
      >
        <div className="brand">
          <img alt="Marble logo" className="logo" src="/images/logo-black.svg" />
          <span className="name">marbleverse</span>
        </div>
        <div className="intro">
          <div className="claim">Augmented Reality NFT Hunt</div>
          <div className="signup-button" onClick={onSignUp}>
            Sign Up Now
          </div>
        </div>
        <ScrollHint />
      </div>
      <Phone name="intro" marbles="nft/marbles.intro.building" />
    </div>
  );
};

export default NftIntro;
