const baseUri = 'https://app.marblever.se';
// const baseUri = 'http://192.168.1.14:3040';

class MarbleverseConnector {
  static async addEmailToCampaign(props) {
    const endpoint = `${baseUri}/api/mautic/addEmailToCampaign`;
    const email = encodeURIComponent(props.email);
    const campaign = encodeURIComponent(props.campaign);
    const uri = `${endpoint}?email=${email}&campaign=${campaign}`;
    return window.fetch(uri);
  }
}

export default MarbleverseConnector;
