export const introLength = 0;
export const postLength = 1500;
export const discoverLength = 1000;
export const toursLength = 4400;
export const dropLength = 1800;
export const geoBasedLength = 1000;
export const arLength = 1500;
export const everyoneLength = 1000;
export const infoMarblesLength = 6500;
export const callToActionLength = 3300;
export const totalLength =
  introLength +
  postLength +
  discoverLength +
  toursLength +
  dropLength +
  geoBasedLength +
  arLength +
  everyoneLength +
  infoMarblesLength +
  callToActionLength;
