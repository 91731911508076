import React from 'react';
import { useTranslation } from 'react-i18next';
import MarbleverseDownloadButton from './MarbleverseDownloadButton';
import MarbleverseFooter from './MarbleverseFooter';
import MarbleversePartners from './MarbleversePartners';
import CreatorsButton from '../common/CreatorsButton';

const MarbleverseCallToAction = props => {
  const { t: $t } = useTranslation();
  return (
    <div className="MarbleverseCallToAction page">
      <div className="jumbotron">
        <div className="cta">{$t('MarbleverseCallToAction')}</div>
        <MarbleverseDownloadButton />
        <MarbleversePartners />
        <MarbleverseFooter copyright="Marble AR, Inc." terms privacy />
      </div>
      <CreatorsButton />
    </div>
  );
};
export default MarbleverseCallToAction;
