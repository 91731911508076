import './styles/main.scss';
import Creators from './components/creators/Creators';
import Forwards from './components/Forwards';
import GetApp from './components/GetApp';
import Marbleverse from './components/marbleverse/Marbleverse';
import Nft from './components/nft/Nft';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './lib/hotjarAnalytics';

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Marbleverse full />} />
        <Route path="/v2" element={<Marbleverse full />} />
        <Route path="/creators" element={<Creators />} />
        <Route path="/getapp" element={<GetApp />} />
        <Route path="/getapp/:campaignId" element={<GetApp />} />
        <Route path="/go/:_id" element={<Forwards />} />
        <Route path="/nfts" element={<Nft />} />
        <Route path="/:_id" element={<Forwards />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default App;
