// const preloadDelay = 500;

export default (name, type) => {
  let playing = false;
  // let firstTime = true;
  const errorHandler = (stage, name, type) => {
    console.log(`${stage} ${name} ${type} interrupted`);
  };
  return (inView, entry) => {
    if (inView && !playing) {
      // console.log(`play ${name} ${type}`);
      const promise = entry.target.play();
      if (promise) promise.catch(() => errorHandler('play', name, type));
      playing = true;
    } else if (!inView && playing) {
      // console.log(`stop ${name} ${type}`);
      entry.target.pause();
      entry.target.currentTime = 0;
      playing = false;
      // } else if (firstTime) {
      //   console.log(`first time ${name} ${type}`);
      //   const promise = entry.target.play();
      //   if (promise) promise.catch(() => errorHandler('first time reset', name, type));
      //   window.setTimeout(() => {
      //     if (playing) return;
      //     console.log(`first time reset ${name} ${type}`);
      //     entry.target.pause();
      //     entry.target.currentTime = 0;
      //   }, preloadDelay);
    }
    // firstTime = false;
  };
};
