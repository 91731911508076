import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const CreatorsPartners = ({ cdn }) => {
  const animation = 'animate__bounceIn';
  return (
    <div id="network" className="CreatorsPartners">
      <div className="section-divider">
        <div className="name">Clients and Network</div>
        <div className="caption">Trusted by Great Brands</div>
        <div className="visual">
          <img alt="divider" src={`${cdn}/images/creators/section.separator.visual.svg`} />
        </div>
      </div>
      <div className="logos">
        <AnimationOnScroll animateIn={animation} animateOnce>
          <img
            alt="Linkin Park"
            className="logo linkinpark"
            src="/images/creators/partners.linkinpark.png"
          />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn={animation} animateOnce>
          <img alt="HSV" className="logo hsv" src={`${cdn}/images/creators/partners.hsv.svg`} />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn={animation} animateOnce>
          <img
            alt="Time Warp"
            className="logo timewarp"
            src={`${cdn}/images/creators/partners.timewarp.svg`}
          />{' '}
        </AnimationOnScroll>
        <AnimationOnScroll animateIn={animation} animateOnce>
          <img
            alt="Techstars"
            className="logo techstars"
            src={`${cdn}/images/creators/partners.techstarsmusic.svg`}
          />{' '}
        </AnimationOnScroll>
        <AnimationOnScroll animateIn={animation} animateOnce>
          <img
            alt="Ericsson"
            className="logo ericsson"
            src="/images/creators/partners.ericsson.svg"
          />{' '}
        </AnimationOnScroll>
        <AnimationOnScroll animateIn={animation} animateOnce>
          <img
            alt="Quake"
            className="logo quake"
            src={`${cdn}/images/creators/partners.quake.svg`}
          />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn={animation} animateOnce>
          <img
            alt="Telekom"
            className="logo telekom"
            src={`${cdn}/images/creators/partners.telekom.svg`}
          />{' '}
        </AnimationOnScroll>
        <AnimationOnScroll animateIn={animation} animateOnce>
          <img
            alt="Hubraum"
            className="logo hubraum"
            src={`${cdn}/images/creators/partners.hubraum.svg`}
          />{' '}
        </AnimationOnScroll>
        <AnimationOnScroll animateIn={animation} animateOnce>
          <img
            alt="SXSW"
            className="logo sxsw"
            src={`${cdn}/images/creators/partners.sxswhackathon.svg`}
          />{' '}
        </AnimationOnScroll>
      </div>
    </div>
  );
};

export default CreatorsPartners;
