import React from 'react';
import { Trans as T } from 'react-i18next';

const MarbleversePartners = () => (
  <div className="MarbleversePartners">
    <div className="caption">
      <T>MarbleversePartners.caption</T>
    </div>
    <div className="logos">
      <img
        alt="Techstars"
        className="logo techstars"
        src="/images/marbleverse/partners.techstarsmusic.svg"
      />
      <img
        alt="Ericsson"
        className="logo ericsson"
        src="/images/marbleverse/partners.ericsson.png"
      />
      <img alt="Quake" className="logo quake" src="/images/marbleverse/partners.quake.svg" />
      <img alt="Telekom" className="logo telekom" src="/images/marbleverse/partners.telekom.svg" />
      <img alt="Hubraum" className="logo hubraum" src="/images/marbleverse/partners.hubraum.svg" />
      <img alt="SXSW" className="logo sxsw" src="/images/marbleverse/partners.sxswhackathon.svg" />
    </div>
  </div>
);

export default MarbleversePartners;
