import React from 'react';
import classnames from 'classnames';

const NftTestimonial = ({ className, statement, by }) => {
  return (
    <div className={classnames('NftTestimonial', className)}>
      <div className="statement">&quot;{statement}&quot;</div>
      <div className="by">- {by}</div>
    </div>
  );
};

export default NftTestimonial;
