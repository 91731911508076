import React from 'react';

const CreatorsHero = ({ cdn, navbar }) => {
  return (
    <div className="CreatorsHero">
      <div className="masked">
        <img
          alt="marble"
          className="marble-pink animate__animated animate__fadeIn"
          src={`${cdn}/images/creators/marble.pink.hires.png`}
        />
        <div className="content">
          {navbar}
          <div className="claim animate__animated animate__fadeInLeft">
            Build Spatial Experiences in No Time.
          </div>
        </div>
        <img alt="mask" className="mask" src="/images/creators/mask.svg" />
      </div>
      <img
        alt="phone"
        className="phone animate__animated animate__fadeIn"
        src="/images/creators/phone.linkin.park.png"
      />
    </div>
  );
};

export default CreatorsHero;
