import React from 'react';
import CreatorsHero from './CreatorsHero';
import CreatorsNavbar from './CreatorsNavbar';
import CreatorsStats from './CreatorsStats';
import CreatorsZero from './CreatorsZero';
import CreatorsBenefit from './CreatorsBenefit';
import CreatorsFeatures from './CreatorsFeatures';
import CreatorsPartners from './CreatorsPartners';
import CreatorsFooter from './CreatorsFooter';
import CreatorsTiers from './CreatorsTiers';
import 'animate.css/animate.min.css';

const showPricing = true;

const cdn = 'https://d370ydiedxu8ib.cloudfront.net';

const Creators = props => {
  const signUp = () => window.open('https://app.marblever.se/sign-up');
  const signIn = () => window.open('https://app.marblever.se/sign-in');
  return (
    <div className="Creators">
      <CreatorsHero
        cdn={cdn}
        navbar={
          <CreatorsNavbar cdn={cdn} onSignUp={signUp} onSignIn={signIn} pricing={showPricing} />
        }
      />
      <div className="page-content">
        <CreatorsStats />
        <CreatorsZero cdn={cdn} />
        <div id="benefits" className="benefits">
          <CreatorsBenefit
            cdn={cdn}
            name="create"
            caption="Create"
            description="Take your fans on a scavenger hunt across a city or the whole country. Create  tours for your true crime podcast. The possibilities are endless."
            cta="What will you build in the Marbleverse?"
            visual="/images/creators/visual.create.svg"
          />
          <CreatorsBenefit
            right
            cdn={cdn}
            name="engage"
            caption="Engage"
            description="Use powerful gamification features like collectibles and rewards for additional fun. You can even drop NFTs, if you’re into it."
            cta="Your fans will love it!"
            visual="/images/creators/visual.engage.svg"
          />
          <CreatorsBenefit
            cdn={cdn}
            name="retain"
            caption="Retain"
            description="Analyze the results and plan retargeting activities for continuous fan retention. Our real-world utilities take your brand and your fans right into Web3."
            cta="Get to the next level of loyalty programs!"
            visual="/images/creators/visual.retain.svg"
          />
        </div>
        <CreatorsFeatures cdn={cdn} />
        {showPricing && <CreatorsTiers cdn={cdn} onSignUp={signUp} />}
        <CreatorsPartners cdn={cdn} />
      </div>
      <CreatorsFooter copyright="Marble AR, Inc." terms privacy />
    </div>
  );
};

export default Creators;
