import credentials from '../credentials';

const { apiToken = '' } = credentials;
const endpoint = 'https://app.marblever.se/api/spotsCount';
// const endpoint = 'http://localhost:3040/api/spotsCount';

const getSpotsCount = async () => {
  const result = await fetch(new Request(`${endpoint}?token=${apiToken}`));
  const json = await result.json();
  return json.results.count;
};

export default getSpotsCount;
