import React, { useEffect } from 'react';
import lax from 'lax.js';
import NftIntro from './NftIntro';
import nftIntroTimeline from './NftIntro.timeline';
import NftActivity from './NftActivity';
import nftActivityPlaceTimeline from './NftActivity.timeline.place';
import nftActivityHuntTimeline from './NftActivity.timeline.hunt';
import nftActivityTradeTimeline from './NftActivity.timeline.trade';
import NftTestimonial from './NftTestimonial';
import nftTestimonialTimelineFirst from './NftTestimonial.timeline.first';
import nftTestimonialTimelineSecond from './NftTestimonial.timeline.second';
import nftTestimonialTimelineThird from './NftTestimonial.timeline.third';
import NftSignUp from './NftSignUp';
import nftSignUpTimeline from './NftSignUp.timeline';
import { totalLength } from './timeline.variables';

const timeline = [
  ...nftIntroTimeline,
  ...nftActivityPlaceTimeline,
  ...nftActivityHuntTimeline,
  ...nftActivityTradeTimeline,
  ...nftTestimonialTimelineFirst,
  ...nftTestimonialTimelineSecond,
  ...nftTestimonialTimelineThird,
  ...nftSignUpTimeline
];

window.lax = { presets: lax.presets };

// const cdn = '';

const Nft = () => {
  useEffect(() => {
    lax.init();
    lax.addDriver('scrollY', () => window.scrollY);
    timeline.forEach(e => lax.addElements(e.selector, e.transforms));
  }, []);
  return (
    <div className="NftLandingPage">
      <NftIntro />
      <NftActivity
        name="place"
        className="place"
        caption="Place"
        description="Place NFTs anywhere in the world."
        image="bridge.compressed.jpeg"
        marbles="nft/marbles.placement.optimized"
        content="nft/content.sky.spirit.jpeg"
      />
      <NftActivity
        name="hunt"
        className="hunt"
        caption="Hunt"
        description="Hunt for millions of NFTs."
        image="pyramid.compressed.jpeg"
        marbles="nft/marbles.hunt.optimized"
        content="nft/content.cube.jpeg"
        reverse
      />
      <NftActivity
        name="trade"
        className="trade"
        caption="Trade"
        description="Trade with users on other platforms."
        image="architecture.compressed.jpeg"
        marbles="nft/marbles.trade.optimized"
        video="nft/rarible.optimized.mov"
        tap
      />
      <NftTestimonial
        className="first"
        statement="A truly groundbreaking way to experience art."
        by="Brian Thomas, Metaverse Investor"
      />
      <NftTestimonial
        className="second"
        statement="Like something out of science fiction."
        by="Alex Bochholt, Metaverse Investor"
      />
      <NftTestimonial
        className="third"
        statement="The possibilities are endless."
        by="Ruben Shultz, Metaverse Investor"
      />
      <NftSignUp />
      <div
        className="fake-scroll"
        style={{ paddingTop: `calc(500vh + 300vw + ${totalLength}px + 500px)` }}
      >
        <div id="signup-anchor" />
      </div>
    </div>
  );
};

export default Nft;
