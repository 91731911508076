import React from 'react';
import dayjs from 'dayjs';

const year = dayjs().format('YYYY');

const CreatorsFooter = ({ copyright, terms, privacy }) => {
  return (
    <div className="CreatorsFooter">
      <img alt="Background" className="background" src="/images/creators/footer.background.svg" />
      <span className="copyright">
        &copy; {year} {copyright}
      </span>
      {terms && (
        <a href="https://app.marblever.se/terms" target="_terms">
          Terms
        </a>
      )}
      {privacy && (
        <a href="https://app.marblever.se/privacy" target="_privacy">
          Privacy Policy
        </a>
      )}
    </div>
  );
};

export default CreatorsFooter;
