import React from 'react';
import classnames from 'classnames';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useTranslation } from 'react-i18next';

const CreatorsFeature = ({ right, cdn, name, caption, description }) => {
  const visualAnimation = right ? 'animate__slideInRight' : 'animate__slideInLeft';
  const textAnimation = right ? 'animate__fadeInLeft' : 'animate__fadeInRight';
  const $t = useTranslation().t;
  return (
    <div className={classnames('CreatorsFeature', name)}>
      <AnimationOnScroll animateIn={visualAnimation} animateOnce>
        <div className="visual">
          <img alt={$t('Common.visual')} src={`${cdn}/images/creators/feature.${name}.jpg`} />
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn={textAnimation} animateOnce>
        <div className="text">
          <div className="caption">{caption}</div>
          <div className="description">{description}</div>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

export default CreatorsFeature;
