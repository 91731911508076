const globals = {
  useGIFsOniOS: false,
  usePNGsOniOS: false,
  appstore: {
    apple: 'https://apps.apple.com/de/app/marble-ar/id1460917736?mt=8',
    google: 'https://play.google.com/store/apps/details?id=com.marblear.prototype',
    huawei: 'https://appgallery.huawei.com/#/app/C102896805'
  },
  channels: {
    '1887hsv': {
      _id: 'gu3Fek2hQJvjixL5D',
      fbEvent: '1887HSV'
    },
    '1887hsvflyer': {
      _id: 'gu3Fek2hQJvjixL5D',
      fbEvent: '1887HSVFlyer'
    },
    cashisclay: {
      _id: 'Gku2F4nRat4TH69in',
      fbEvent: 'Cashisclay'
    },
    cryptopharaos: {
      _id: 'gfbmu9NTgEkBfwtdm',
      fbEvent: 'CryptoPharaos'
    },
    heidelverse: {
      _id: 'M5Ey6hYCjPLf9xKrT',
      fbEvent: 'Heidelverse'
    },
    meteora20: {
      _id: 'HEdRhzkBuMPHion3L',
      fbEvent: 'Meteora20'
    },
    pharaos: {
      _id: 'gfbmu9NTgEkBfwtdm',
      fbEvent: 'CryptoPharaos'
    },
    santamonica: {
      _id: 'guzDJfh3L77kfEKgD',
      fbEvent: 'SantaMonica'
    },
    stygo: {
      _id: 'Sz6thQxEcmMFNiapr',
      fbEvent: 'Stygo'
    },
    timewarp: {
      _id: 'Pz9kmnf5XcT5tW88y',
      fbEvent: 'TimeWarp'
    },
    timewarpmemoriescontest: {
      _id: 'guzDJfh3L77kfEKgD',
      fbEvent: 'TimeWarp'
    },
    twitch: {
      _id: '7GrR7HotK2Rw9MpAX',
      fbEvent: 'Twitch'
    }
  },
  getapp: {
    stygo: {
      fbEvent: 'GetAppStygo'
    }
  },
  facebook: {
    appId: '268575760972815',
    analytics: {
      pixelId: '314267150852815'
    }
  },
  google: {
    analytics: {
      id: 'G-Y9VQCZWF1K',
      app_name: 'marble prototype'
    },
    ads: {
      id: 'AW-682525537'
    },
    tagManager: {
      id: 'GTM-MW36N9P',
      app_name: 'marble prototype'
    }
  },
  hotjar: {
    id: 3847579
  }
};

export default globals;
