import classnames from 'classnames';
import React from 'react';
import Session from '../../lib/Session';

const bubblesTotalDesktop = 20;
const bubblesTotalMobile = 7;

const MarbleverseBubbles = ({ animated, video, cdn }) => {
  const bubblesTotal = Session.get('runningOnMobile') ? bubblesTotalMobile : bubblesTotalDesktop;
  const style =
    animated || video ? undefined : { backgroundImage: `url(${cdn}/images/backdrop.jpg)` };
  // React does not allow to set the muted flag of a background video
  const videoHTML = video
    ? `
        <video
          src="${cdn}/videos/marble.loop.1024.mp4"
          autoPlay
          muted
          loop
          playsInline
          preload="none"
        />
  `
    : undefined;
  return (
    <div className={classnames('MarbleverseBubbles', { animated })} style={style}>
      {video && <div className="video-wrapper" dangerouslySetInnerHTML={{ __html: videoHTML }} />}
      {animated &&
        [...Array(bubblesTotal).keys()].map(index => (
          <div key={index} className={`bubble bubble-${index + 1}`} />
        ))}
    </div>
  );
};

export default MarbleverseBubbles;
