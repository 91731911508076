import globals from '../globals';

const { facebook: { analytics: { pixelId } = {} } = {} } = globals;

class FacebookAnalytics {
  constructor() {
    if (!pixelId) return;
    const w = (window.fbq = function() {
      w.callMethod ? w.callMethod.apply(w, arguments) : w.queue.push(arguments);
    });
    w.push = w;
    w.loaded = true;
    w.version = '2.0';
    w.queue = [];
    window._fbq = window.fbq;
    this.track = this.track.bind(this);
    this.track('init', pixelId);
    this.track('track', 'PageView');
    this.track('set', 'agent', 'tmgoogletagmanager', pixelId);
    this.createScriptElement();
  }

  createScriptElement() {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://connect.facebook.net/en_US/fbevents.js`;
    document.body.appendChild(script);
  }

  track() {
    // console.log(`fbq: ${[...arguments].map(j => JSON.stringify(j)).join(', ')}`);
    window.fbq(...arguments);
  }

  eventName(canonicalEventName) {
    return canonicalEventName
      .split('.')
      .map(([initial, ...rest]) => [initial.toUpperCase(), ...rest].join(''))
      .join('');
  }

  trackEvent({ eventName, customData }) {
    this.track('trackCustom', this.eventName(eventName), customData);
  }
}

export default new FacebookAnalytics();
