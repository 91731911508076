import React, { useEffect, useState } from 'react';
import getSpotsCount from '../../lib/getSpotsCount';

const step = 97;
const interval = 23;
const fallbackMarbleCount = 11000;

const CreatorsStats = () => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    let innerCounter = 0;
    const countdown = targetCount => {
      innerCounter += step;
      setCount(Math.min(innerCounter, targetCount));
      if (innerCounter < targetCount) setTimeout(() => countdown(targetCount), interval);
    };
    const startCountdown = targetCount => {
      // setTargetCount(targetCount);
      countdown(targetCount);
    };
    getSpotsCount()
      .then(startCountdown)
      .catch(error => console.log(error) && startCountdown(fallbackMarbleCount));
  }, []);
  return (
    <div className="CreatorsStats">
      <div className="stats">
        <div className="number">{new Intl.NumberFormat('en-US').format(count)}</div>
        <div className="caption">User-Generated Marbles</div>
      </div>
      <div className="stats">
        <div className="number">Millions</div>
        <div className="caption">Location-Based Info Marbles</div>
      </div>
      <div className="stats">
        <div className="number">4,5*</div>
        <div className="caption">App Store Rating</div>
      </div>
    </div>
  );
};

export default CreatorsStats;
