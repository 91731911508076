import {
  huntLength,
  introLength,
  placementLength,
  testimonialLength,
  tradeLength
} from './timeline.variables';

const marblesStart = `5*screenHeight+2*screenWidth+${introLength +
  placementLength +
  huntLength +
  tradeLength +
  3 * testimonialLength}`;

const marblesSpeed = 200;

const timeline = [
  {
    selector: '.NftSignUp',
    transforms: {
      scrollY: {
        translateY: [
          [
            0,
            `4*screenHeight+2*screenWidth+${introLength +
              placementLength +
              huntLength +
              tradeLength +
              3 * testimonialLength}-1`,
            `4*screenHeight+2*screenWidth+${introLength +
              placementLength +
              huntLength +
              tradeLength +
              3 * testimonialLength}`,
            `5*screenHeight+2*screenWidth+${introLength +
              placementLength +
              huntLength +
              tradeLength +
              3 * testimonialLength}`
          ],
          ['2*screenHeight', '2*screenHeight', 'screenHeight', 0]
        ]
      }
    }
  },
  {
    selector: '.NftSignUp .marbles.one',
    transforms: {
      scrollY: {
        translateZ: [
          [0, 1e9],
          [1, 1]
        ],
        opacity: [
          [0, marblesStart, `${marblesStart}+${marblesSpeed}`],
          [0, 0, 0.8]
        ]
      }
    }
  },
  {
    selector: '.NftSignUp .marbles.two',
    transforms: {
      scrollY: {
        translateZ: [
          [0, 1e9],
          [2, 2]
        ],
        opacity: [
          [0, `${marblesStart}+${marblesSpeed}`, `${marblesStart}+${2 * marblesSpeed}`],
          [0, 0, 0.8]
        ]
      }
    }
  },
  {
    selector: '.NftSignUp .marbles.three',
    transforms: {
      scrollY: {
        translateZ: [
          [0, 1e9],
          [3, 3]
        ],
        opacity: [
          [0, `${marblesStart}+${2 * marblesSpeed}`, `${marblesStart}+${3 * marblesSpeed}`],
          [0, 0, 0.8]
        ]
      }
    }
  },
  {
    selector: '.NftSignUp .marbles.four',
    transforms: {
      scrollY: {
        translateZ: [
          [0, 1e9],
          [4, 4]
        ],
        opacity: [
          [0, `${marblesStart}+${3 * marblesSpeed}`, `${marblesStart}+${4 * marblesSpeed}`],
          [0, 0, 0.8]
        ]
      }
    }
  },
  {
    selector: '.NftSignUp .marbles.five',
    transforms: {
      scrollY: {
        translateZ: [
          [0, 1e9],
          [5, 5]
        ],
        opacity: [
          [0, `${marblesStart}+${4 * marblesSpeed}`, `${marblesStart}+${5 * marblesSpeed}`],
          [0, 0, 0.8]
        ]
      }
    }
  }
];

export default timeline;
