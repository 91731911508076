import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import facebookAnalytics from '../lib/facebookAnalytics';
import globals from '../globals';

const delay = 200;
const channelBase = 'https://app.marblever.se/channel';
const { channels } = globals;

const Forwards = () => {
  const params = useParams();
  const navigate = useNavigate();
  const channelId = params._id;
  const channel = channelId && channels[channelId];
  if (channel) {
    facebookAnalytics.track('trackCustom', channel.fbEvent);
    window.setTimeout(() => (document.location.href = `${channelBase}/${channel._id}`), delay);
  } else {
    window.setTimeout(() => navigate('/'), delay);
  }
  return <div className="forward" />;
};

export default Forwards;
