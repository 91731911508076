import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const CreatorsZero = ({ cdn }) => {
  const animation = 'animate__zoomIn';
  return (
    <div className="CreatorsZero">
      <div className="claim">Your Easiest Way Into the Spatial Internet</div>
      <div className="zeros">
        <AnimationOnScroll animateIn={animation} animateOnce>
          <img alt="Zero Setup" src={`${cdn}/images/creators/zero.setup.png`} />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn={animation} animateOnce>
          <img alt="Zero Code" src={`${cdn}/images/creators/zero.code.png`} />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn={animation} animateOnce>
          <img alt="Zero Limits" src={`${cdn}/images/creators/zero.limits.png`} />
        </AnimationOnScroll>
      </div>
    </div>
  );
};

export default CreatorsZero;
