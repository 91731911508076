import React from 'react';
import { InView } from 'react-intersection-observer';
import ControlledVideo from './ControlledVideo';
import { useTranslation } from 'react-i18next';

const Phone = ({ cdn, name, marbles, useFallbackMarbles, content, video, tap }) => {
  const $t = useTranslation().t;
  return (
    <div className="Phone">
      <div className="wrapper">
        <div className="mask">
          {marbles && (
            <div className="marbles">
              <ControlledVideo
                name={name}
                type="marbles"
                width="1080"
                height="1920"
                video={marbles}
                useFallback={useFallbackMarbles}
                cdn={cdn}
              />
            </div>
          )}
          {tap && (
            <div className="tap">
              <ControlledVideo name={name} type="marble tap" video="tap-white" cdn={cdn} />
            </div>
          )}
          <img alt={$t('Phone.marbleUX')} className="ux" src={`${cdn}/images/app.ux.png`} />
          {video && (
            <InView
              as="video"
              // onChange={getVideoController(name, 'content video')}
              className="app-content"
              src={`/videos/${video}`}
              loop
              muted
              playsInline
              autoPlay
            />
          )}
          {content && (
            <img
              alt={$t('Phone.marbleContent')}
              className="app-content"
              src={`${cdn}/images/${content}`}
            />
          )}
        </div>
        <img
          alt={$t('Phone.iPhoneFrame')}
          className="frame"
          src={`${cdn}/images/iphonex.noshadow.png`}
        />
      </div>
    </div>
  );
};

export default Phone;
