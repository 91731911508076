import React from 'react';
import Session from '../lib/Session';
import globals from '../globals';
import { useNavigate, useParams } from 'react-router-dom';
import facebookAnalytics from '../lib/facebookAnalytics';

const { appstore: { apple, google, huawei } = {}, getapp } = globals;

const GetApp = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { campaignId } = params;
  const campaign = campaignId && getapp[campaignId];
  const appstore = Session.get('runningOniOS')
    ? apple
    : Session.get('runningOnAndroid')
    ? google
    : Session.get('supportsHMSCore')
    ? huawei
    : undefined;
  const forward = () => (appstore ? (document.location.href = appstore) : navigate('/'));
  if (campaign) facebookAnalytics.track('trackCustom', campaign.fbEvent);
  setTimeout(forward, 200);
  return <div className="GetApp" />;
};

export default GetApp;
