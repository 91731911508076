import facebookAnalytics from './facebookAnalytics';
import googleTagManager from './googleTagManager';

class Analytics {
  constructor() {
    this.trackScreenView = this.trackScreenView.bind(this);
    // this.addHistoryListener();
  }

  trackEvent({ eventName, category, label, customData: data }) {
    const customData = {
      ...data
    };
    facebookAnalytics.trackEvent({ eventName, category, label, customData });
    googleTagManager.trackEvent({ eventName, category, label, customData });
  }

  // addHistoryListener() {
  //   history.listen(this.trackScreenView);
  // }

  trackScreenView(location) {
    this.trackEvent({ eventName: 'screen.view', customData: { screen: location.pathname } });
  }

  reportConversion(conversionId, callback) {
    googleTagManager.reportConversion(conversionId, callback);
  }
}

const analytics = new Analytics();

analytics.trackScreenView(window.location);

export default analytics;
