import classnames from 'classnames';
import React from 'react';
import Phone from '../common/Phone';

const MarbleverseUseCase = props => {
  const {
    cdn,
    name,
    background,
    className,
    caption,
    captionVariant,
    marbles,
    useFallbackMarbles,
    content,
    tap,
    video
  } = props;
  return (
    <div className={classnames('MarbleverseUseCase', name, 'page', className)}>
      <div className="background" style={{ backgroundImage: `url(${cdn}/images/${background})` }}>
        <div className="content">
          <div className={classnames('caption', captionVariant)}>{caption}</div>
          <div className="visual">
            <Phone
              cdn={cdn}
              name={name}
              marbles={marbles}
              useFallbackMarbles={useFallbackMarbles}
              content={content}
              video={video}
              tap={tap}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarbleverseUseCase;
