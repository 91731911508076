import {
  huntLength,
  introLength,
  placementLength,
  testimonialLength,
  tradeLength
} from './timeline.variables';

const timeline = [
  {
    selector: '.NftTestimonial.second',
    transforms: {
      scrollY: {
        translateY: [
          [
            0,
            `4*screenHeight+2*screenWidth+${introLength +
              placementLength +
              huntLength +
              tradeLength +
              3 * testimonialLength}`,
            `5*screenHeight+2*screenWidth+${introLength +
              placementLength +
              huntLength +
              tradeLength +
              3 * testimonialLength}`
          ],
          [0, 0, '-screenHeight']
        ],
        translateX: [
          [
            0,
            `4*screenHeight+${introLength +
              placementLength +
              huntLength +
              tradeLength +
              testimonialLength}`,
            `4*screenHeight+1*screenWidth+${introLength +
              placementLength +
              huntLength +
              tradeLength +
              testimonialLength}`,
            `4*screenHeight+1*screenWidth+${introLength +
              placementLength +
              huntLength +
              tradeLength +
              2 * testimonialLength}`,
            `4*screenHeight+2*screenWidth+${introLength +
              placementLength +
              huntLength +
              tradeLength +
              2 * testimonialLength}`
          ],
          ['screenWidth', 'screenWidth', 0, 0, '-screenWidth']
        ]
      }
    }
  }
];

export default timeline;
