import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n/config';
import './lib/deviceDetection';
import App from './App';
import reportWebVitals from './reportWebVitals';
import history from './routing/history';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

// handle S3 redirects to #! urls; allows serving single-page app from bucket
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) history.replace(path);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
