const onUpdateFactory = ({ shown, showLength, hideLength }) => (driverValues, domElement) => {
  const { scrollY: [scrollY] = [] } = driverValues;
  if (!shown && scrollY >= showLength && scrollY <= hideLength) {
    shown = true;
    domElement.classList.add('visible');
    // console.log(`show ${domElement.classList}`);
  } else if (shown && (scrollY < showLength || scrollY > hideLength)) {
    shown = false;
    domElement.classList.remove('visible');
    // console.log(`hide ${domElement.classList}`);
  }
};

export default onUpdateFactory;
