import React from 'react';
import CreatorsFeature from './CreatorsFeature';

const CreatorsFeatures = ({ cdn }) => {
  return (
    <div id="features" className="CreatorsFeatures">
      <div className="section-divider">
        <div className="name">Features</div>
        <div className="caption">Easy to use, but powerful</div>
        <div className="visual">
          <img alt="divider" src="/images/creators/section.separator.visual.svg" />
        </div>
      </div>
      <div className="features">
        <CreatorsFeature
          right
          cdn={cdn}
          name="any.ecosystem"
          caption="Any Content, Any Ecosystem"
          description="Create photos, videos or audio on-the-fly, or re-use existing content from YouTube, Spotify, and many other platforms. You can even embed custom web-pages or PDFs."
        />
        <CreatorsFeature
          cdn={cdn}
          name="remote.drops"
          caption="Remote Drops"
          description="Create and maintain your Marbles on a map, using your desktop browser. If you’re building experiences for several cities, you can even clone Marbles, keeping their contents in sync."
        />
        <CreatorsFeature
          right
          cdn={cdn}
          name="channel"
          caption="Branded Channel"
          description="Your channel is your branded space in the Marbleverse. It is your landing page and portal to the real-world meteaverse."
        />
      </div>
    </div>
  );
};

export default CreatorsFeatures;
