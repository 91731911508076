import { discoverLength, introLength, postLength, toursLength } from './timeline.variables';
import onUpdateFactory from '../../lib/onUpdateFactory';

const blendDelay = 300;

const selector = '.MarbleverseUseCase.tours';

const startLength = introLength + postLength + discoverLength;
const endLength = startLength + toursLength;

const showLength = startLength + 2 * window.innerHeight;
const hideLength = endLength + 4 * window.innerHeight;
const graffitiShown = false;
const foodShown = false;
const truecrimeShown = false;

const timeline = [
  {
    selector: `${selector}-graffiti`,
    transforms: {
      scrollY: {
        translateY: [
          [
            `2*screenHeight+${startLength}-1`,
            `2*screenHeight+${startLength}`,
            `3*screenHeight+${startLength}`,
            `3*screenHeight+${endLength - 1}`,
            `3*screenHeight+${endLength}`
          ],
          ['2*screenHeight', 'screenHeight', 0, 0, '-screenHeight']
        ]
      }
    },
    onUpdate: onUpdateFactory({ graffitiShown, showLength, hideLength })
  },
  {
    selector: `${selector}-graffiti .content .caption`,
    transforms: {
      scrollY: {
        translateY: [
          [`2*screenHeight+${startLength}`, `3*screenHeight+${startLength}`],
          ['-screenHeight', 0]
        ]
      }
    }
  },
  {
    selector: `${selector}-graffiti .Phone`,
    transforms: {
      scrollY: {
        translateY: [
          [
            `2*screenHeight+${startLength}`,
            `3*screenHeight+${startLength}`,
            `3*screenHeight+${endLength - 1}`,
            `3*screenHeight+${endLength}`
          ],
          ['-screenHeight', 0, 0, 'screenHeight']
        ]
      }
    }
  },
  {
    selector: `${selector}-graffiti .Phone .tap`,
    transforms: {
      scrollY: {
        opacity: [
          [`3*screenHeight+${startLength}`, `3*screenHeight+${startLength + 1}`],
          [0, 0.7]
        ]
      }
    }
  },
  {
    selector: `${selector}-graffiti .Phone .app-content`,
    transforms: {
      scrollY: {
        translateY: [
          [0, `3*screenHeight+${startLength + 300}`, `3*screenHeight+${startLength + 800}`],
          ['screenHeight', 'screenHeight', 0]
        ]
      }
    }
  },
  {
    selector: `${selector}-food`,
    transforms: {
      scrollY: {
        translateY: [
          [
            `3*screenHeight+${startLength + 1799}`,
            `3*screenHeight+${startLength + 1800}`,
            `3*screenHeight+${endLength - 1}`,
            `3*screenHeight+${endLength}`
          ],
          ['screenHeight', 0, 0, '-screenHeight']
        ],
        opacity: [
          [
            `3*screenHeight+${startLength + 1800}`,
            `3*screenHeight+${startLength + 1800 + blendDelay}`
          ],
          [0, 1]
        ]
      }
    },
    onUpdate: onUpdateFactory({ shown: foodShown, showLength, hideLength })
  },
  {
    selector: `${selector}-truecrime`,
    transforms: {
      scrollY: {
        translateY: [
          [
            `3*screenHeight+${startLength + 2800 + blendDelay - 1}`,
            `3*screenHeight+${startLength + 2800 + blendDelay}`,
            `3*screenHeight+${endLength}`,
            `4*screenHeight+${endLength}`
          ],
          ['screenHeight', 0, 0, '-screenHeight']
        ],
        opacity: [
          [
            `3*screenHeight+${startLength + 2800 + blendDelay}`,
            `3*screenHeight+${startLength + 2800 + blendDelay * 2}`
          ],
          [0, 1]
        ]
      }
    },
    onUpdate: onUpdateFactory({ shown: truecrimeShown, showLength, hideLength })
  },
  {
    selector: `${selector}-truecrime .Phone`,
    transforms: {
      scrollY: {
        translateY: [
          [`3*screenHeight+${endLength}`, `4*screenHeight+${endLength}`],
          [0, 'screenHeight']
        ]
      }
    }
  }
];

export default timeline;
