import { introLength, placementLength, totalLength } from './timeline.variables';

const timeline = [
  {
    selector: '.NftActivity.place',
    transforms: {
      scrollY: {
        translateY: [
          [
            `0*screenHeight+${introLength}`,
            `1*screenHeight+${introLength}`,
            `1*screenHeight+${introLength + placementLength}`,
            `2*screenHeight+${introLength + placementLength}`
          ],
          ['screenHeight+300', 0, 0, '-screenHeight']
        ]
      }
    }
  },
  {
    selector: '.NftActivity.place .box',
    transforms: {
      scrollY: {
        translateY: [
          [
            `0*screenHeight+${introLength}`,
            `1*screenHeight+${introLength}-200`,
            `1*screenHeight+${introLength + placementLength}`,
            `2*screenHeight+${introLength + placementLength}-500`
          ],
          ['screenHeight+300', 0, 0, '-screenHeight']
        ]
      }
    }
  },
  {
    selector: '.NftActivity.place .Phone',
    transforms: {
      scrollY: {
        translateY: [
          [
            `0*screenHeight+${introLength}`,
            `1*screenHeight+${introLength}-50`,
            `1*screenHeight+${introLength + placementLength}`,
            `2*screenHeight+${introLength + placementLength}-50`
          ],
          ['screenHeight+300', 0, 0, '-screenHeight']
        ]
      }
    }
  },
  {
    selector: '.NftActivity.place .app-content',
    transforms: {
      scrollY: {
        translateY: [
          [
            `1*screenHeight+${introLength}`,
            `1*screenHeight+${introLength}+500`,
            `1*screenHeight+${introLength}+500+300`,
            `1*screenHeight+${introLength + placementLength}`
          ],
          ['elHeight', 0, 0, 'elHeight']
        ],
        translateX: [
          [100, totalLength],
          [0, 0]
        ]
      }
    }
  },
  {
    selector: '.NftActivity.place .marbles',
    transforms: {
      scrollY: {
        translateY: [
          [0, `1*screenHeight+${introLength}+500+300`, `1*screenHeight+${introLength}+500+301`],
          ['1*screenHeight', '1*screenHeight', '-0.5*elHeight']
        ]
      }
    }
  }
];

export default timeline;
