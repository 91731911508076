import { introLength, postLength } from './timeline.variables';
import onUpdateFactory from '../../lib/onUpdateFactory';

const selector = '.MarbleverseUseCase.post';

const startLength = introLength;
const endLength = startLength + postLength;

const showLength = startLength;
const hideLength = endLength + 2 * window.innerHeight;
const shown = false;

const timeline = [
  {
    selector,
    transforms: {
      scrollY: {
        translateY: [
          [
            introLength,
            `1*screenHeight+${startLength}`,
            `1*screenHeight+${endLength}`,
            `2*screenHeight+${endLength}`
          ],
          ['screenHeight', 0, 0, '-screenHeight']
        ]
      }
    },
    onUpdate: onUpdateFactory({ shown, showLength, hideLength })
  },
  {
    selector: `${selector} .Phone`,
    transforms: {
      scrollY: {
        translateY: [
          [
            `1*screenHeight+${startLength}`,
            `1*screenHeight+${startLength + 500}`,
            `1*screenHeight+${endLength}`,
            `2*screenHeight+${endLength}`
          ],
          ['screenHeight', 0, 0, 'screenHeight']
        ]
      }
    }
  },
  {
    selector: `${selector} .Phone .marbles`,
    transforms: {
      scrollY: {
        translateY: [
          [`1*screenHeight+${startLength}`, `1*screenHeight+${startLength + 500}`],
          ['-screenHeight-0.5*elHeight', '-0.5*elHeight']
        ]
      }
    }
  },
  {
    selector: `${selector} .Phone .tap`,
    transforms: {
      scrollY: {
        opacity: [
          [`1*screenHeight+${startLength + 500}`, `1*screenHeight+${startLength + 501}`],
          [0, 0.7]
        ]
      }
    }
  },
  {
    selector: `${selector} .Phone .app-content`,
    transforms: {
      scrollY: {
        translateY: [
          [`1*screenHeight+${startLength + 500}`, `1*screenHeight+${startLength + 1000}`],
          ['screenHeight', 0]
        ]
      }
    }
  }
];

export default timeline;
