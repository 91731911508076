import React, { useRef, useState } from 'react';
import MarbleverseConnector from '../../lib/MarbleverseConnector';
import classnames from 'classnames';

const campaign = 308;
const emailRegExp = /\S+@\S+\.\S+/;

const NftSignUp = () => {
  // const [error, setError] = useState();
  const [mode, setMode] = useState();
  const [email, setEmail] = useState('');
  const onKeyDown = event => {
    if (event.keyCode !== 13) return;
    event.preventDefault();
    onSignUp();
  };
  const onChange = event => setEmail(event?.target?.value);
  const onSignUp = () => {
    setMode('loading');
    MarbleverseConnector.addEmailToCampaign({ email, campaign })
      .then(() => setMode('success'))
      .catch(() => setMode('error'));
  };
  const loading = mode === 'loading';
  const invalidEmail = !emailRegExp.test(email);
  const buttonDisabled = loading || invalidEmail;
  const inputRef = useRef();
  return (
    <div className="NftSignUp">
      <div className="visual" style={{ backgroundImage: 'url(/images/nft/signup.background.jpg)' }}>
        <div
          className="marbles one"
          style={{ backgroundImage: 'url(/images/nft/signup.marbles.1.compressed.png)' }}
        />
        <div
          className="marbles two"
          style={{ backgroundImage: 'url(/images/nft/signup.marbles.2.compressed.png)' }}
        />
        <div
          className="marbles three"
          style={{ backgroundImage: 'url(/images/nft/signup.marbles.3.compressed.png)' }}
        />
        <div
          className="marbles four"
          style={{ backgroundImage: 'url(/images/nft/signup.marbles.4.compressed.png)' }}
        />
        <div
          className="marbles five"
          style={{ backgroundImage: 'url(/images/nft/signup.marbles.5.compressed.png)' }}
        />
        <div className="signup">
          <div className="call-to-action">Take me to the other side</div>
          <form>
            <input
              type="text"
              placeholder="Your e-mail address"
              value={email}
              ref={inputRef}
              disabled={mode === 'loading'}
              onKeyDown={onKeyDown}
              onChange={onChange}
            />
            <div
              className={classnames('button', { disabled: buttonDisabled, wait: loading })}
              onClick={buttonDisabled ? undefined : onSignUp}
            >
              {loading ? 'Wait...' : 'Sign Up Now'}
            </div>
          </form>
          <div className="feedback">
            {mode === 'success' && (
              <span className="success">Thanks, you have been added to our waiting list!</span>
            )}
            {mode === 'error' && (
              <span className="error">An error occurred. Please try again.</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NftSignUp;
