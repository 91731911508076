import React from 'react';
import AppstoreBadges from '../common/AppstoreBadges';

const MarbleverseDownloadButton = () => {
  return (
    <div className="MarbleverseDownloadButton">
      <AppstoreBadges />
    </div>
  );
};

export default MarbleverseDownloadButton;
