import {
  arLength,
  discoverLength,
  dropLength,
  geoBasedLength,
  introLength,
  postLength,
  toursLength
} from './timeline.variables';
import onUpdateFactory from '../../lib/onUpdateFactory';

const selector = '.MarbleverseFeature.augmentedreality';

const startLength =
  introLength + postLength + discoverLength + toursLength + dropLength + geoBasedLength;
const endLength = startLength + arLength;

const showLength = startLength + 5 * window.innerHeight;
const hideLength = endLength + 7 * window.innerHeight;
const shown = false;

const timeline = [
  {
    selector,
    transforms: {
      scrollY: {
        translateY: [
          [
            `5*screenHeight+${startLength}-1`,
            `5*screenHeight+${startLength}`,
            `6*screenHeight+${startLength}`,
            `6*screenHeight+${endLength}`,
            `7*screenHeight+${endLength}`
          ],
          ['2*screenHeight', 'screenHeight', 0, 0, '-screenHeight']
        ]
      }
    },
    onUpdate: onUpdateFactory({ shown, showLength, hideLength })
  },
  {
    selector: `${selector} .infobox`,
    transforms: {
      scrollY: {
        translateY: [
          [0, `6*screenHeight+${endLength}`, `7*screenHeight+${endLength}`],
          [0, 0, 'screenHeight']
        ],
        opacity: [
          [0, `6*screenHeight+${endLength}`, `6*screenHeight+${endLength + 300}`],
          [1, 1, 0]
        ]
      }
    }
  },
  {
    selector: `${selector} .infobox .caption .text`,
    transforms: {
      scrollY: {
        opacity: [
          [`6*screenHeight+${startLength}`, `6*screenHeight+${startLength}+200`],
          [0, 1]
        ]
      }
    }
  },
  {
    selector: `${selector} svg`,
    transforms: {
      scrollY: {
        scaleX: [
          [`6*screenHeight+${startLength}+100`, `6*screenHeight+${startLength + 500}`],
          [0, 0.99]
        ]
      }
    }
  },
  {
    selector: `${selector} .infobox .description`,
    transforms: {
      scrollY: {
        opacity: [
          [`6*screenHeight+${startLength}+100`, `6*screenHeight+${startLength + 500}`],
          [0, 1]
        ]
      }
    }
  },
  {
    selector: `${selector} .marbles`,
    transforms: {
      scrollY: {
        translateY: [
          [`5*screenHeight+${startLength}`, `6*screenHeight+${startLength}`],
          ['-screenHeight', '-0.5*elHeight']
        ]
      }
    }
  },
  {
    selector: `${selector} .Phone .tap`,
    transforms: {
      scrollY: {
        opacity: [
          [`6*screenHeight+${startLength + 100}`, `2*screenHeight+${startLength + 500}`],
          [0, 0.7]
        ]
      }
    }
  },
  {
    selector: `${selector} .Phone .app-content`,
    transforms: {
      scrollY: {
        translateY: [
          [`6*screenHeight+${startLength + 500}`, `6*screenHeight+${startLength + 1000}`],
          ['screenHeight', 0]
        ]
      }
    }
  }
];

export default timeline;
