import Carousel from 'nuka-carousel';
import React from 'react';
import CarouselNextButton from './CarouselNextButton';
import CarouselPrevButton from './CarouselPrevButton';
import { useTranslation } from 'react-i18next';

const VideoBox = props => {
  const { caption, videos = [] } = props;
  const leftControls = props => (videos.length ? <CarouselPrevButton {...props} /> : null);
  const rightControls = props => (videos.length ? <CarouselNextButton {...props} /> : null);
  const $t = useTranslation().t;
  const slides = videos.map((video, index) => (
    <div key={index} className="Slide">
      <iframe
        title={$t('VideoBox.video')}
        src={`https://www.youtube.com/embed/${video}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  ));
  return (
    <div className="VideoBox">
      {caption && <div className="caption">{caption}</div>}
      <div className="SliderContainer">
        <Carousel
          className="Slider"
          // slideIndex={1}
          // beforeSlide={this.onBeforeSlide}
          // afterSlide={this.onAfterSlide}
          renderCenterLeftControls={leftControls}
          renderCenterRightControls={rightControls}
          renderBottomCenterControls={null}
          // disableEdgeSwiping
          swiping
          dragging
        >
          {slides}
        </Carousel>
      </div>
    </div>
  );
};

export default VideoBox;
