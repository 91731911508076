import { introLength } from './timeline.variables';

const timeline = [
  {
    selector: '.NftIntro .hero-banner',
    transforms: {
      scrollY: {
        translateY: [
          [introLength, `${introLength}+screenHeight`],
          [0, '-screenHeight']
        ]
      }
    }
  },
  {
    selector: '.NftIntro .ScrollHint',
    transforms: {
      scrollY: {
        opacity: [
          [0, 200],
          [1, 0]
        ]
      }
    }
  },
  {
    selector: '.NftIntro .Phone',
    transforms: {
      scrollY: {
        translateY: [
          [0, 300, introLength, `${introLength}+0.8*screenHeight`],
          ['screenHeight', '0.2*screenHeight', '0.2*screenHeight', '-screenHeight']
        ]
      }
    }
  },
  {
    selector: '.NftIntro .Phone .marbles',
    transforms: {
      scrollY: {
        translateY: [
          [0, 300, introLength, `${introLength}+screenHeight`],
          ['-0.8*screenHeight', 0, 0, `0.5*screenHeight`]
        ]
      }
    }
  }
];

export default timeline;
