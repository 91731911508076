import { InView } from 'react-intersection-observer';
import React, { useEffect, useRef } from 'react';
import getVideoController from '../../lib/getVideoController';
import Session from '../../lib/Session';
import globals from '../../globals';
import classnames from 'classnames';

const { useGIFsOniOS, usePNGsOniOS } = globals;

const ControlledVideo = props => {
  const {
    cdn,
    name,
    type,
    className,
    video,
    useFallback,
    width,
    height,
    defaultPlaybackRate
  } = props;
  const runningOniOS = Session.get('runningOniOS');
  const runningOnMacOS = Session.get('runningOnMacOS');
  const runningInSafari = Session.get('runningInSafari');
  const useGIFs = runningOniOS && useFallback && useGIFsOniOS;
  const usePNGs = runningOniOS && useFallback && usePNGsOniOS;
  const videoRef = useRef();
  const mp4First = runningOnMacOS && runningInSafari;
  const webM = { src: `${cdn}/videos/${video}-vp9-chrome.webm`, type: 'video/webm' };
  const mp4 = { src: `${cdn}/videos/${video}-hevc-safari.mp4`, type: 'video/mp4' };
  const sources = mp4First ? [mp4, webM] : [webM, mp4];

  useEffect(() => {
    if (useGIFs || !defaultPlaybackRate) return;
    videoRef.current.node.playbackRate = defaultPlaybackRate;
  }, []);
  const enhancedClassNames = classnames('ControlledVideo', className, name);
  return usePNGs || useGIFs ? (
    <InView
      as="img"
      ref={videoRef}
      src={`${cdn}/videos/${video}.${usePNGs ? 'png' : 'gif'}`}
      className={enhancedClassNames}
      width={usePNGs ? 675 : 480}
      height={usePNGs ? 1462 : undefined}
    />
  ) : (
    <InView
      as={useGIFs ? 'img' : 'video'}
      ref={videoRef}
      onChange={useGIFs ? undefined : getVideoController(name, type)}
      className={enhancedClassNames}
      width={width}
      height={height}
      loop
      muted
      playsInline
    >
      {sources.map((source, index) => (
        <source key={index} src={source.src} type={source.type} />
      ))}
    </InView>
  );
};

export default ControlledVideo;
