import React from 'react';
import { useTranslation } from 'react-i18next';
import VideoBox from '../common/VideoBox';
import MarbleverseDownloadButton from './MarbleverseDownloadButton';
import ScrollHint from '../common/ScrollHint';
import CreatorsButton from '../common/CreatorsButton';

const MarbleverseIntro = props => {
  const { cdn, full } = props;
  const $t = useTranslation().t;
  return (
    <div className="MarbleverseIntro page">
      <div className="jumbotron">
        <div className="teaser">
          <div className="brand">
            <img
              alt={$t('MarbleverseIntro.marbleverseLogo')}
              src={`${cdn}/images/logo-white.svg`}
            />
            {$t('Common.marbleverse')}
          </div>
          <div className="claim">{$t('MarbleverseIntro.claim')}</div>
          <MarbleverseDownloadButton />
        </div>
        <div className="video">
          <VideoBox videos={['5c5qx8YO1uY']} />
        </div>
      </div>
      <CreatorsButton />
      {full && <ScrollHint />}
    </div>
  );
};
export default MarbleverseIntro;
