const Session = {
  variables: {},
  set(variable, value) {
    this.variables[variable] = value;
  },
  get(variable) {
    return this.variables[variable];
  }
};

export default Session;
