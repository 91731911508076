import {
  arLength,
  discoverLength,
  dropLength,
  everyoneLength,
  geoBasedLength,
  infoMarblesLength,
  introLength,
  postLength,
  toursLength
} from './timeline.variables';
import onUpdateFactory from '../../lib/onUpdateFactory';

const selector = '.MarbleverseInfoMarble';

const startLength =
  introLength +
  postLength +
  discoverLength +
  toursLength +
  dropLength +
  geoBasedLength +
  arLength +
  everyoneLength -
  500;
const endLength = startLength + infoMarblesLength;

const showLength = startLength + 7 * window.innerHeight;
const hideLength = endLength + 7 * window.innerHeight;

const wikipediaShown = false;
const metroShown = false;
const bandsintownShown = false;

const timeline = [
  {
    selector: `${selector}.wikipedia`,
    transforms: {
      scrollY: {
        translateY: [
          [
            `7*screenHeight+${startLength - 1}`,
            `7*screenHeight+${startLength}`,
            `7*screenHeight+${startLength + 2600}`,
            `7*screenHeight+${startLength + 2601}`
          ],
          ['2*screenHeight', 0, 0, '-1.3*screenHeight']
        ]
      }
    },
    onUpdate: onUpdateFactory({ shown: wikipediaShown, showLength, hideLength })
  },
  {
    selector: `${selector}.wikipedia .Phone`,
    transforms: {
      scrollY: {
        translateY: [
          [`7*screenHeight+${startLength}`, `7*screenHeight+${startLength + 500}`],
          ['screenHeight', 0]
        ]
      }
    }
  },
  {
    selector: `${selector}.wikipedia .ux`,
    transforms: {
      scrollY: {
        opacity: [
          [0, `7*screenHeight+${startLength + 1700}`, `7*screenHeight+${startLength + 1701}`],
          [1, 1, 0]
        ]
      }
    }
  },
  {
    selector: `${selector}.wikipedia .Phone .marbles`,
    transforms: {
      scrollY: {
        translateY: [
          [
            `7*screenHeight+${startLength}`,
            `7*screenHeight+${startLength + 500}`,
            `7*screenHeight+${startLength + 1700}`,
            `7*screenHeight+${startLength + 1701}`
          ],
          ['-screenHeight', '-0.5*elHeight', '-0.5*elHeight', '-screenHeight']
        ]
      }
    }
  },
  {
    selector: `${selector}.wikipedia .caption`,
    transforms: {
      scrollY: {
        opacity: [
          [`7*screenHeight+${startLength + 300}`, `7*screenHeight+${startLength + 500}`],
          [0, 1]
        ]
      }
    }
  },
  {
    selector: `${selector}.wikipedia .description`,
    transforms: {
      scrollY: {
        opacity: [
          [
            `7*screenHeight+${startLength + 400}`,
            `7*screenHeight+${startLength + 600}`,
            `7*screenHeight+${startLength + 2300}`,
            `7*screenHeight+${startLength + 2500}`
          ],
          [0, 1, 1, 0]
        ]
      }
    }
  },
  {
    selector: `${selector}.wikipedia .tap`,
    transforms: {
      scrollY: {
        opacity: [
          [
            `7*screenHeight+${startLength + 1000}`,
            `7*screenHeight+${startLength + 1001}`,
            `7*screenHeight+${startLength + 1700}`,
            `7*screenHeight+${startLength + 1701}`
          ],
          [0, 0.7, 0.7, 0]
        ]
      }
    }
  },
  {
    selector: `${selector}.wikipedia .app-content`,
    transforms: {
      scrollY: {
        translateY: [
          [
            `7*screenHeight+${startLength + 1400}`,
            `7*screenHeight+${startLength + 1700}`,
            `7*screenHeight+${startLength + 2300}`,
            `7*screenHeight+${startLength + 2600}`
          ],
          ['screenHeight', 0, 0, 'screenHeight']
        ]
      }
    }
  },
  {
    selector: `${selector}.metro`,
    transforms: {
      scrollY: {
        translateY: [
          [
            `7*screenHeight+${startLength + 2200}`,
            `7*screenHeight+${startLength + 2201}`,
            `7*screenHeight+${startLength + 4300}`,
            `7*screenHeight+${startLength + 4301}`
          ],
          ['2*screenHeight', 0, 0, '-1.3*screenHeight']
        ]
      }
    },
    onUpdate: onUpdateFactory({ shown: metroShown, showLength, hideLength })
  },
  {
    selector: `${selector}.metro .ux`,
    transforms: {
      scrollY: {
        opacity: [
          [0, `7*screenHeight+${startLength + 3500}`, `7*screenHeight+${startLength + 3501}`],
          [1, 1, 0]
        ]
      }
    }
  },
  {
    selector: `${selector}.metro .description`,
    transforms: {
      scrollY: {
        opacity: [
          [
            `7*screenHeight+${startLength + 2500}`,
            `7*screenHeight+${startLength + 2700}`,
            `7*screenHeight+${startLength + 4100}`,
            `7*screenHeight+${startLength + 4300}`
          ],
          [0, 1, 1, 0]
        ]
      }
    }
  },
  {
    selector: `${selector}.metro .tap`,
    transforms: {
      scrollY: {
        opacity: [
          [
            `7*screenHeight+${startLength + 3000}`,
            `7*screenHeight+${startLength + 3001}`,
            `7*screenHeight+${startLength + 3500}`,
            `7*screenHeight+${startLength + 3501}`
          ],
          [0, 0.7, 0.7, 0]
        ]
      }
    }
  },
  {
    selector: `${selector}.metro .app-content`,
    transforms: {
      scrollY: {
        translateY: [
          [
            `7*screenHeight+${startLength + 3300}`,
            `7*screenHeight+${startLength + 3500}`,
            `7*screenHeight+${startLength + 4100}`,
            `7*screenHeight+${startLength + 4300}`
          ],
          ['screenHeight', 0, 0, 'screenHeight']
        ]
      }
    }
  },
  {
    selector: `${selector}.bandsintown`,
    transforms: {
      scrollY: {
        translateY: [
          [
            `7*screenHeight+${startLength + 4100}`,
            `7*screenHeight+${startLength + 4101}`,
            `7*screenHeight+${endLength - 500}`,
            `7*screenHeight+${endLength}`
          ],
          ['2*screenHeight', 0, 0, '-1.3*screenHeight']
        ]
      }
    },
    onUpdate: onUpdateFactory({ shown: bandsintownShown, showLength, hideLength })
  },
  {
    selector: `${selector}.bandsintown .ux`,
    transforms: {
      scrollY: {
        opacity: [
          [0, `7*screenHeight+${startLength + 5300}`, `7*screenHeight+${startLength + 5301}`],
          [1, 1, 0]
        ]
      }
    }
  },
  {
    selector: `${selector}.bandsintown .description`,
    transforms: {
      scrollY: {
        opacity: [
          [
            `7*screenHeight+${startLength + 4300}`,
            `7*screenHeight+${startLength + 4500}`,
            `7*screenHeight+${startLength + 5900}`,
            `7*screenHeight+${startLength + 6100}`
          ],
          [0, 1, 1, 0]
        ]
      }
    }
  },
  {
    selector: `${selector}.bandsintown .tap`,
    transforms: {
      scrollY: {
        opacity: [
          [
            `7*screenHeight+${startLength + 4800}`,
            `7*screenHeight+${startLength + 4801}`,
            `7*screenHeight+${startLength + 5300}`,
            `7*screenHeight+${startLength + 5301}`
          ],
          [0, 0.7, 0.7, 0]
        ]
      }
    }
  },
  {
    selector: `${selector}.bandsintown .app-content`,
    transforms: {
      scrollY: {
        translateY: [
          [`7*screenHeight+${startLength + 5000}`, `7*screenHeight+${startLength + 5300}`],
          ['screenHeight', 0]
        ]
      }
    }
  }
];

export default timeline;
