import {
  arLength,
  callToActionLength,
  discoverLength,
  dropLength,
  everyoneLength,
  geoBasedLength,
  infoMarblesLength,
  introLength,
  postLength,
  toursLength
} from './timeline.variables';
import onUpdateFactory from '../../lib/onUpdateFactory';

const selector = '.MarbleverseCallToAction';

const startLength =
  introLength +
  postLength +
  discoverLength +
  toursLength +
  dropLength +
  geoBasedLength +
  arLength +
  everyoneLength +
  infoMarblesLength -
  500;
const endLength = startLength + callToActionLength;

const showLength = startLength + 7 * window.innerHeight;
const hideLength = endLength + 7 * window.innerHeight + 1000;
const shown = false;

const timeline = [
  {
    selector,
    transforms: {
      scrollY: {
        translateY: [
          [`7*screenHeight+${startLength - 1}`, `7*screenHeight+${startLength}`],
          ['+screenHeight', 0]
        ]
      }
    },
    onUpdate: onUpdateFactory({ shown, showLength, hideLength })
  },
  {
    selector: `${selector} .cta`,
    transforms: {
      scrollY: {
        opacity: [
          [`7*screenHeight+${startLength}`, `7*screenHeight+${startLength + 300}`],
          [0, 1]
        ]
      }
    }
  },
  {
    selector: `${selector} .MarbleverseDownloadButton`,
    transforms: {
      scrollY: {
        opacity: [
          [`7*screenHeight+${startLength + 300}`, `7*screenHeight+${startLength + 600}`],
          [0, 1]
        ]
      }
    }
  },
  {
    selector: `${selector} .MarbleverseFooter`,
    transforms: {
      scrollY: {
        opacity: [
          [`7*screenHeight+${startLength + 600}`, `7*screenHeight+${startLength + 900}`],
          [0, 0.5]
        ]
      }
    }
  },
  {
    selector: `${selector} .MarbleversePartners`,
    transforms: {
      scrollY: {
        opacity: [
          [`7*screenHeight+${startLength + 600}`, `7*screenHeight+${startLength + 900}`],
          [0, 1]
        ]
      }
    }
  }
];

export default timeline;
