import Session from './Session';

let platformLogInfo = '';
const html = document.getElementsByTagName('html')[0];

const setEnvironmentVariables = function() {
  platformLogInfo = 'Running in webbrowser';
  // App running on iOS?
  const iPadOS = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints > 2;
  const runningOniOS =
    iPadOS ||
    (navigator.platform && /iPad|iPhone|iPod|iOS/.test(navigator.platform)) ||
    (navigator.userAgent && /iPad|iPhone|iPod|iOS/.test(navigator.userAgent));
  Session.set('runningOniOS', runningOniOS);
  platformLogInfo += runningOniOS ? ' (iOS)' : '';
  // App running on Android?
  const runningOnAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
  Session.set('runningOnAndroid', runningOnAndroid);
  platformLogInfo += runningOnAndroid ? ' (Android)' : '';
  const runningOnMacOS = /Mac/.test(navigator.platform);
  Session.set('runningOnMacOS', runningOnMacOS);
  platformLogInfo += runningOnMacOS ? ' (macOS)' : '';
  const runningOnFireOS =
    typeof window.device !== 'undefined' && window.device.platform === 'amazon-fireos';
  Session.set('runningOnFireOS', runningOnFireOS);
  platformLogInfo += runningOnFireOS ? ' (FireOS)' : '';
  Session.set('runningOnMobile', runningOniOS || runningOnAndroid || runningOnFireOS);
  const runningInSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  Session.set('runningInSafari', runningInSafari);
  const runningInEdge = navigator.userAgent && /Edge/.test(navigator.userAgent);
  Session.set('runningInEdge', runningInEdge);
  // App supporting Huawei Mobile Services?
  const supportsHMSCore = navigator.userAgent && /HMSCore/.test(navigator.userAgent);
  Session.set('supportsHMSCore', supportsHMSCore);
  platformLogInfo += supportsHMSCore ? ' (HMSCore)' : '';
};
setEnvironmentVariables();

const setHtmlClasses = () => {
  console.log(platformLogInfo);
  const runningInUnity = Session.get('runningInUnity');
  const runningOnMobile = Session.get('runningOnMobile');
  const runningAsMobileApp = Session.get('runningAsMobileApp');
  const runningOniOS = Session.get('runningOniOS');
  const runningOnAndroid = Session.get('runningOnAndroid');
  const runningOnMacOS = Session.get('runningOnMacOS');
  const runningOnFireOS = Session.get('runningOnFireOS');
  const runningInSafari = Session.get('runningInSafari');
  const runningInEdge = Session.get('runningInEdge');
  const supportsHMSCore = Session.get('supportsHMSCore');
  runningOniOS && html.classList.add('ios');
  runningOnAndroid && html.classList.add('android');
  runningOnMacOS && html.classList.add('macos');
  runningOnFireOS && html.classList.add('fireos');
  runningOnMobile ? html.classList.add('mobile') : html.classList.add('desktop');
  runningInUnity
    ? html.classList.add('unity')
    : runningAsMobileApp
    ? html.classList.add('nativeapp')
    : html.classList.add('webapp');
  runningInSafari && html.classList.add('safari');
  runningInEdge && html.classList.add('edge');
  supportsHMSCore && html.classList.add('hmscore');
};

setHtmlClasses();
