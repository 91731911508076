import React from 'react';
import Phone from '../common/Phone';
import classnames from 'classnames';

const NftActivity = props => {
  const {
    name,
    className,
    caption,
    description,
    image,
    marbles,
    content,
    video,
    reverse,
    tap
  } = props;
  return (
    <div className={classnames('NftActivity', className, { reverse })}>
      <div className="visual">
        <div className="box">
          <div className="caption">{caption}</div>
          <div className="description">{description}</div>
        </div>
        <div className="background" style={{ backgroundImage: `url(/images/nft/${image})` }}>
          <Phone name={name} marbles={marbles} content={content} video={video} tap={tap} />
        </div>
      </div>
    </div>
  );
};

export default NftActivity;
