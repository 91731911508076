import {
  discoverLength,
  dropLength,
  introLength,
  postLength,
  toursLength
} from './timeline.variables';
import onUpdateFactory from '../../lib/onUpdateFactory';

const selector = '.MarbleverseUseCase.drop';

const startLength = introLength + postLength + discoverLength + toursLength;
const endLength = startLength + dropLength;

const showLength = startLength + 3 * window.innerHeight;
const hideLength = endLength + 5 * window.innerHeight;

const shown = false;

const timeline = [
  {
    selector,
    transforms: {
      scrollY: {
        translateY: [
          [
            `3*screenHeight+${startLength}-1`,
            `3*screenHeight+${startLength}`,
            `4*screenHeight+${startLength}`,
            `4*screenHeight+${endLength}`,
            `5*screenHeight+${endLength}`
          ],
          ['2*screenHeight', 'screenHeight', 0, 0, '-screenHeight']
        ]
      }
    },
    onUpdate: onUpdateFactory({ shown, showLength, hideLength })
  },
  {
    selector: `${selector} .content .caption`,
    transforms: {
      scrollY: {
        translateY: [
          [`4*screenHeight+${startLength}`, `4*screenHeight+${startLength}`],
          ['-screenHeight', 0]
        ]
      }
    }
  },
  {
    selector: `${selector} .Phone`,
    transforms: {
      scrollY: {
        translateY: [
          [`3*screenHeight+${startLength}`, `4*screenHeight+${startLength}`],
          ['-screenHeight', 0]
        ]
      }
    }
  },
  {
    selector: `${selector} .Phone .tap`,
    transforms: {
      scrollY: {
        opacity: [
          [`4*screenHeight+${startLength}`, `4*screenHeight+${startLength + 1}`],
          [0, 0.7]
        ]
      }
    }
  },
  {
    selector: `${selector} .Phone .app-content`,
    transforms: {
      scrollY: {
        translateY: [
          [0, `4*screenHeight+${startLength + 300}`, `4*screenHeight+${startLength + 800}`],
          ['screenHeight', 'screenHeight', 0]
        ]
      }
    }
  }
];

export default timeline;
