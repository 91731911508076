import globals from '../globals';
import Session from './Session';

const {
  google: {
    tagManager: { id: gtmId, app_name }, // eslint-disable-line camelcase
    ads: { id: adsId }
  }
} = globals;

class GoogleTagManager {
  constructor() {
    this.runningOniOS = Session.get('runningOniOS');
    this.runningInUnity = Session.get('runningInUnity');
    // Google Analytics won't work inside webview on iOS
    // if (this.runningOniOS && this.runningInUnity) return;
    window.dataLayer = window.dataLayer || [];
    this.track({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    this.createScriptElement();
    this.gtag = this.gtag.bind(this);
    this.track = this.track.bind(this);
    this.reportConversion = this.reportConversion.bind(this);
  }

  createScriptElement() {
    const firstScript = document.getElementsByTagName('script')[0];
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    firstScript.parentNode.insertBefore(script, firstScript);
  }

  gtag() {
    // Google Analytics won't work inside webview on iOS
    // if (this.runningOniOS && this.runningInUnity) return;
    // console.log(`ga: ${[...arguments].map(j => JSON.stringify(j)).join(', ')}`);
    window.dataLayer.push(arguments);
  }

  track() {
    this.gtag(...arguments);
  }

  eventName(canonicalEventName) {
    return canonicalEventName.split('.').join('_');
  }

  trackEvent({ eventName, category, label, customData }) {
    const data = { app_name, event_category: category, event_label: label, ...customData }; // eslint-disable-line camelcase
    // in Google terms, the event name is the event action
    this.track('event', this.eventName(eventName), data);
  }

  reportConversion(conversionId, callback) {
    this.gtag('event', 'conversion', {
      send_to: `${adsId}/${conversionId}`,
      event_callback: callback
    });
  }
}

export default new GoogleTagManager();
