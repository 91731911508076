import { discoverLength, introLength, postLength } from './timeline.variables';
import onUpdateFactory from '../../lib/onUpdateFactory';

const selector = '.MarbleverseUseCase.discover';

const startLength = introLength + postLength;
const endLength = startLength + discoverLength;

const showLength = startLength + window.innerHeight;
const hideLength = endLength + 3 * window.innerHeight;
const shown = false;

const timeline = [
  {
    selector,
    transforms: {
      scrollY: {
        translateY: [
          [
            `1*screenHeight+${startLength - 1}`,
            `1*screenHeight+${startLength}`,
            `2*screenHeight+${startLength}`,
            `2*screenHeight+${endLength}`,
            `3*screenHeight+${endLength}`
          ],
          ['2*screenHeight', 'screenHeight', 0, 0, '-screenHeight']
        ]
      }
    },
    onUpdate: onUpdateFactory({ shown, showLength, hideLength })
  },
  {
    selector: `${selector} .content .caption`,
    transforms: {
      scrollY: {
        translateY: [
          [`1*screenHeight+${startLength}`, `2*screenHeight+${startLength}`],
          ['-screenHeight', 0]
        ]
      }
    }
  },
  {
    selector: `${selector} .Phone`,
    transforms: {
      scrollY: {
        translateY: [
          [
            `1*screenHeight+${startLength}`,
            `2*screenHeight+${startLength}`,
            `2*screenHeight+${endLength}`,
            `3*screenHeight+${endLength}`
          ],
          ['-screenHeight', 0, 0, 'screenHeight']
        ]
      }
    }
  },
  {
    selector: `${selector} .Phone .tap`,
    transforms: {
      scrollY: {
        opacity: [
          [`2*screenHeight+${startLength}`, `2*screenHeight+${startLength + 1}`],
          [0, 0.7]
        ]
      }
    }
  },
  {
    selector: `${selector} .Phone .app-content`,
    transforms: {
      scrollY: {
        translateY: [
          [0, `2*screenHeight+${startLength}`, `2*screenHeight+${startLength + 500}`],
          ['screenHeight', 'screenHeight', 0]
        ]
      }
    }
  }
];

export default timeline;
