import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const LoadingSpinner = ({ hidden }) => {
  const $t = useTranslation().t;

  return (
    <img
      alt={$t('Common.loading')}
      className={classnames('LoadingSpinner', { hidden })}
      src="/images/spinner.svg"
    />
  );
};

export default LoadingSpinner;
