import React, { useState } from 'react';
import Modal from '../common/Modal';
import LoadingSpinner from '../common/LoadingSpinner';
import { useTranslation } from 'react-i18next';

const formId = 5;
const endpoint = `https://mautic.marblear.com/form/submit?formId=${formId}`;
const emailRegExp = /\S+@\S+\.\S+/;

const CreatorsContactForm = ({ show, onClose, context }) => {
  const [mode, setMode] = useState('form');
  const [error, setError] = useState();
  const [errorField, setErrorField] = useState();
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [busy, setBusy] = useState(false);
  // eslint-disable-next-line camelcase
  const [message, setMessage] = useState(''); // eslint-disable-line camelcase
  const disabled = busy || !firstname || !lastname || !email || !message; // eslint-disable-line camelcase
  const $t = useTranslation().t;
  const checkFields = () => {
    if (!emailRegExp.test(email)) {
      setError('Please enter a valid email address');
      setErrorField('email');
      return false;
    }
    return true;
  };
  const onCloseButtonClick = () => {
    setMode('form');
    setBusy(false);
    onClose && onClose();
  };
  const onSubmit = e => {
    e.preventDefault();
    if (!checkFields()) return;
    setBusy(true);
    setError(undefined);
    setErrorField(undefined);
    const params = {
      formId,
      firstname,
      lastname,
      email,
      f_message: message,
      messenger: 1,
      context
    };
    const data = new URLSearchParams();
    Object.keys(params).forEach(k => data.append(`mauticform[${k}]`, params[k]));
    const headers = { 'X-Requested-With': 'XMLHttpRequest' };
    fetch(endpoint, { method: 'post', body: data, headers })
      // axios({ method: 'post', url: endpoint, data, headers })
      .then(() => setMode('confirmation'))
      .catch(e => setError(e.message))
      .finally(() => setBusy(false));
  };
  return show ? (
    <div className="CreatorsContactForm">
      <Modal onClose={onCloseButtonClick}>
        {mode === 'confirmation' && (
          <div className="confirmation">
            <div className="modal-header">{$t('CreatorsContactForm.thankYou')}</div>
            <div className="message">{$t('CreatorsContactForm.messageReceived')}</div>
          </div>
        )}
        {mode === 'form' && (
          <>
            <div className="modal-header">{$t('CreatorsContactForm.contactUs')}</div>
            <div className="form-row">
              <label>
                {$t('CreatorsContactForm.firstName')}
                <span>*</span>
              </label>
              <input
                id="firstname"
                type="text"
                value={firstname}
                onChange={e => setFirstname(e.currentTarget.value)}
              />
            </div>
            <div className="form-row">
              <label>
                {$t('CreatorsContactForm.lastName')}
                <span>*</span>
              </label>
              <input
                id="lastname"
                type="text"
                value={lastname}
                onChange={e => setLastname(e.currentTarget.value)}
              />
            </div>
            <div className="form-row">
              <label>
                {$t('CreatorsContactForm.email')}
                <span>*</span> {errorField === 'email' && <span className="error">{error}</span>}
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={e => setEmail(e.currentTarget.value)}
              />
            </div>
            <div className="form-row">
              <label>
                {$t('CreatorsContactForm.message')}
                <span>*</span>
              </label>
              <textarea
                id="message"
                rows={5}
                value={message}
                onChange={e => setMessage(e.currentTarget.value)}
              />
            </div>
          </>
        )}
        <div className="actions">
          {mode === 'form' && (
            <button className="primary" onClick={onSubmit} disabled={disabled}>
              <LoadingSpinner hidden={!busy} />
              {!busy && $t('Common.send')}
            </button>
          )}
          <button onClick={onCloseButtonClick}>{$t('Common.close')}</button>
        </div>
        <div className="error">{!errorField && error}</div>
      </Modal>
    </div>
  ) : null;
};

export default CreatorsContactForm;
