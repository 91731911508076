import React from 'react';

const Modal = ({ children, onClose }) => {
  return (
    <div className="Modal">
      <div className="backdrop" onClick={onClose} />
      <div className="modal-dialog">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
