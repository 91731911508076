import classnames from 'classnames';
import React from 'react';
import Phone from '../common/Phone';
import Line from '../common/Line';
import ControlledVideo from '../common/ControlledVideo';

const MarbleverseFeature = props => {
  const {
    cdn,
    name,
    background,
    caption,
    className,
    descriptions,
    marbles,
    useFallbackMarbles,
    content,
    tap,
    video,
    playbackRate,
    visual
  } = props;
  const showPhone = !!marbles || !!content || !!tap;
  const backgroundImage = background ? `url(${cdn}/images/${background})` : undefined;
  return (
    <div className={classnames('MarbleverseFeature', name, 'page', className)}>
      <div className="background" style={{ backgroundImage }}>
        <div className="feature-content">
          <div className="infobox">
            <div className="caption">
              <div className="text">{caption}</div>
              <Line />
            </div>
            {descriptions?.map((description, index) => (
              <div key={index} className="description">
                {description}
              </div>
            ))}
          </div>
          <div className="visual">
            {showPhone && (
              <Phone
                name={name}
                marbles={marbles}
                useFallbackMarbles={useFallbackMarbles}
                content={content}
                video={video}
                tap={tap}
                cdn={cdn}
              />
            )}
            {video && (
              <ControlledVideo
                name={name}
                className="video"
                type="visual"
                video={video}
                defaultPlaybackRate={playbackRate}
                cdn={cdn}
              />
            )}
            {visual}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarbleverseFeature;
