import {
  huntLength,
  introLength,
  placementLength,
  totalLength,
  tradeLength
} from './timeline.variables';

const timeline = [
  {
    selector: '.NftActivity.trade',
    transforms: {
      scrollY: {
        translateY: [
          [
            `2*screenHeight+${introLength + placementLength + huntLength}`,
            `3*screenHeight+${introLength + placementLength + huntLength}-100`,
            `3*screenHeight+${introLength + placementLength + huntLength + tradeLength}`,
            `4*screenHeight+${introLength + placementLength + huntLength + tradeLength}-500`
          ],
          ['screenHeight+300', 0, 0, '-screenHeight']
        ]
      }
    }
  },
  {
    selector: '.NftActivity.trade .box',
    transforms: {
      scrollY: {
        translateY: [
          [
            `2*screenHeight+${introLength + placementLength + huntLength}`,
            `3*screenHeight+${introLength + placementLength + huntLength}-200`,
            `3*screenHeight+${introLength + placementLength + huntLength + tradeLength}`,
            `4*screenHeight+${introLength + placementLength + huntLength + tradeLength}-500`
          ],
          ['screenHeight+300', 0, 0, '-screenHeight']
        ]
      }
    }
  },
  {
    selector: '.NftActivity.trade .Phone',
    transforms: {
      scrollY: {
        translateY: [
          [
            `2*screenHeight+${introLength + placementLength + huntLength}`,
            `3*screenHeight+${introLength + placementLength + huntLength}-50`,
            `3*screenHeight+${introLength + placementLength + huntLength + tradeLength}`,
            `4*screenHeight+${introLength + placementLength + huntLength + tradeLength}-50`
          ],
          ['screenHeight+300', 0, 0, '-screenHeight']
        ]
      }
    }
  },
  {
    selector: '.NftActivity.trade .app-content',
    transforms: {
      scrollY: {
        translateY: [
          [
            `3*screenHeight+${introLength + placementLength + huntLength}`,
            `3*screenHeight+${introLength + placementLength + huntLength}+300`,
            `3*screenHeight+${introLength + placementLength + huntLength + tradeLength}`
          ],
          ['elHeight', 'elHeight', 0]
        ],
        translateX: [
          [0, totalLength],
          [0, 0]
        ]
      }
    }
  }
];

export default timeline;
