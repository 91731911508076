import globals from '../globals';

const { hotjar: { id } = {} } = globals;

const baseUrl = 'https://static.hotjar.com/c/hotjar-';

class HotjarAnalytics {
  constructor() {
    if (!id) return;
    const h = window;
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: id, hjsv: 6 };
    const src = `${baseUrl}${h._hjSettings.hjid}.js?sv={h._hjSettings.hjsv`;
    this.createScriptElement(src);
  }

  createScriptElement(src) {
    const script = document.createElement('script');
    script.async = true;
    script.src = src;
    document.body.appendChild(script);
  }
}

export default new HotjarAnalytics();
