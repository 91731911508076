import {
  discoverLength,
  dropLength,
  geoBasedLength,
  introLength,
  postLength,
  toursLength
} from './timeline.variables';
import onUpdateFactory from '../../lib/onUpdateFactory';

const selector = '.MarbleverseFeature.geobased';

const startLength = introLength + postLength + discoverLength + toursLength + dropLength;
const endLength = startLength + geoBasedLength;

const showLength = startLength + 4 * window.innerHeight;
const hideLength = endLength + 6 * window.innerHeight;
const shown = false;

const timeline = [
  {
    selector,
    transforms: {
      scrollY: {
        translateY: [
          [
            `4*screenHeight+${startLength}-1`,
            `4*screenHeight+${startLength}`,
            `5*screenHeight+${startLength}`,
            `5*screenHeight+${endLength}`,
            `6*screenHeight+${endLength}`
          ],
          ['2*screenHeight', 'screenHeight', 0, 0, '-screenHeight']
        ]
      }
    },
    onUpdate: onUpdateFactory({ shown, showLength, hideLength })
  },
  {
    selector: `${selector} .infobox .caption .text`,
    transforms: {
      scrollY: {
        opacity: [
          [`5*screenHeight+${startLength}`, `5*screenHeight+${startLength}+200`],
          [0, 1]
        ]
      }
    }
  },
  {
    selector: `${selector} svg`,
    transforms: {
      scrollY: {
        scaleX: [
          [`5*screenHeight+${startLength}+100`, `5*screenHeight+${startLength + 500}`],
          [0, 0.99]
        ]
      }
    }
  },
  {
    selector: `${selector} .infobox .description`,
    transforms: {
      scrollY: {
        opacity: [
          [`5*screenHeight+${startLength}+100`, `5*screenHeight+${startLength + 500}`],
          [0, 1]
        ]
      }
    }
  }
];

export default timeline;
