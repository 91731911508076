import React from 'react';
import { useTranslation } from 'react-i18next';

const CreatorsButton = props => {
  const $t = useTranslation().t;
  const openCreatorsPage = () => window.open('/creators', 'creators');
  return (
    <div className="CreatorsButton" onClick={openCreatorsPage}>
      {$t('CreatorsButton.forBrands')} &rarr;
    </div>
  );
};

export default CreatorsButton;
