import React from 'react';
import analytics from '../../lib/analytics';
import globals from '../../globals';
import Session from '../../lib/Session';
import { useTranslation } from 'react-i18next';

const { appstore } = globals;

const POPUP_WINDOW_NAME = 'store';

const AppstoreBadges = ({ light }) => {
  const runningOnMobile = Session.get('runningOnMobile');
  const runningOniOS = Session.get('runningOniOS');
  const runningOnAndroid = Session.get('runningOnAndroid');
  const supportsHMSCore = Session.get('supportsHMSCore');
  const showAppleBadge = !runningOnMobile || runningOniOS;
  const showGoogleBadge = !runningOnMobile || runningOnAndroid;
  const showHuaweiBadge = supportsHMSCore;
  const $t = useTranslation().t;
  const openAppStoreLink = (link, conversionId) => {
    window.setTimeout(() => window.open(appstore.apple, POPUP_WINDOW_NAME), 100);
    analytics.reportConversion(conversionId);
  };
  return (
    <div className="AppstoreBadges">
      {showAppleBadge && (
        <img
          alt={$t('AppstoreBadges.appStoreBadge')}
          className="apple appstore-badge"
          src={`/images/appstore.badge.apple${light ? '.light' : '.dark'}.svg`}
          onClick={() => openAppStoreLink(appstore.apple, 'FX-TCO6D0rkBEOGGusUC')}
        />
      )}
      {showGoogleBadge && (
        <img
          alt={$t('AppstoreBadges.playStoreBadge')}
          className="google appstore-badge"
          src={`/images/appstore.badge.google${light ? '.light.svg' : '.dark.png'}`}
          onClick={() => openAppStoreLink(appstore.google, 'xU1gCJmI0rkBEOGGusUC')}
        />
      )}
      {showHuaweiBadge && (
        <img
          alt={$t('AppstoreBadges.appGalleryBadge')}
          className="huawei appstore-badge"
          src="/images/appstore.badge.huawei.dark.png"
          onClick={() => openAppStoreLink(appstore.huawei, 'xU1gCJmI0rkBEOGGusUC')}
        />
      )}
    </div>
  );
};

export default AppstoreBadges;
