import React, { useState } from 'react';
import CreatorsTier from './CreatorsTier';
import CreatorsContactForm from './CreatorsContactForm';

const CreatorsTiers = ({ onSignUp }) => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [contactContext, setContactContext] = useState();
  const onShowContactForm = context => {
    setContactContext(context);
    setShowContactForm(true);
  };
  return (
    <div id="pricing" className="CreatorsTiers">
      <CreatorsTier
        left
        name="Free"
        targetGroup="For individuals"
        // pricePerMonth="0"
        cta="Sign Up"
        onClick={onSignUp}
      >
        <div className="group">Unlimited Marbles</div>
        <div className="group">Basic Building Blocks</div>
        <ul>
          <li>Videos, Photos, Audio</li>
          <li>YouTube, Vimeo, Twitch</li>
          <li>Spotify, Soundcloud</li>
        </ul>
        <div className="group">Social Features</div>
        <ul>
          <li>Chats and Private Groups</li>
        </ul>
      </CreatorsTier>
      <CreatorsTier
        name="Professional"
        targetGroup="For brands"
        // pricePerMonth="500"
        cta="Contact Us"
        onClick={() => onShowContactForm('Professional Pricing Tier')}
      >
        {/* <div className="group">Remote Marble Placement</div> */}
        <div className="group">Advanced Building Blocks</div>
        <ul>
          <li>Collectibles</li>
          <li>Sweepstakes</li>
          <li>Web Pages, PDFs</li>
          <li>Shopify Shops</li>
        </ul>
        <div className="group">Marketing Tools</div>
        <ul>
          <li>Channel (Landing Page)</li>
          <li>Retargeting: E-Mail + Push</li>
          <li>In-App Promotions</li>
          <li>Marble Cloning</li>
        </ul>
        <div className="group">Support</div>
        <div className="group">Optional: Branded Marble</div>
        <div className="comment">Special Deals for Non-Profits and Startups</div>
      </CreatorsTier>
      <CreatorsTier
        right
        name="Partner"
        targetGroup="For integrations"
        // customPrice="Custom"
        cta="Contact Us"
        onClick={() => onShowContactForm('Partner Pricing Tier')}
      >
        <p>
          Get any location-based content or data into the Marbleverse, e. g. retail estate listings.
        </p>
        <p>Represent yourself with branded Info Marbles.</p>
      </CreatorsTier>
      <div
        className="background"
        style={{ backgroundImage: 'url(/images/creators/tiers.background.svg)' }}
      />
      <CreatorsContactForm
        show={showContactForm}
        context={contactContext}
        onClose={() => setShowContactForm(false)}
      />
    </div>
  );
};

export default CreatorsTiers;
