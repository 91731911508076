import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const CreatorsTier = ({
  left,
  right,
  name,
  targetGroup,
  pricePerMonth,
  customPrice,
  cta,
  children,
  onClick,
  onContactFormClose
}) => {
  const animation = left
    ? 'animate__slideInLeft'
    : right
    ? 'animate__slideInRight'
    : 'animate__fadeIn';
  const duration = left || right ? 1 : 1.5;
  return (
    <AnimationOnScroll animateIn={animation} duration={duration} animateOnce>
      <div className="CreatorsTier">
        <div className="name">{name}</div>
        <div className="target-group">{targetGroup}</div>
        <div className="price">
          {customPrice && <div className="custom">{customPrice}</div>}
          {pricePerMonth && (
            <>
              <span className="currency">$</span>
              <span className="amount">{pricePerMonth}</span>
              <span className="duration">/mo</span>
            </>
          )}
        </div>
        <div className="cta">
          <button onClick={onClick}>{cta}</button>
        </div>
        <div className="features">{children}</div>
      </div>
    </AnimationOnScroll>
  );
};

export default CreatorsTier;
