import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

const year = dayjs().format('YYYY');

const MarbleverseFooter = ({ terms, privacy, copyright }) => {
  const $t = useTranslation().t;
  return (
    <div className="MarbleverseFooter">
      <span className="copyright">
        &copy; {year} {copyright}
      </span>
      {terms && (
        <a href="https://app.marblever.se/terms" target="_terms">
          {$t('MarbleverseFooter.terms')}
        </a>
      )}
      {privacy && (
        <a href="https://app.marblever.se/privacy" target="_privacy">
          {$t('MarbleverseFooter.privacyPolicy')}
        </a>
      )}
    </div>
  );
};

export default MarbleverseFooter;
