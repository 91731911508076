import { huntLength, introLength, placementLength, totalLength } from './timeline.variables';

const timeline = [
  {
    selector: '.NftActivity.hunt',
    transforms: {
      scrollY: {
        translateY: [
          [
            `1*screenHeight+${introLength + placementLength}`,
            `2*screenHeight+${introLength + placementLength}`,
            `2*screenHeight+${introLength + placementLength + huntLength}`,
            `3*screenHeight+${introLength + placementLength + huntLength}`
          ],
          ['screenHeight+300', 0, 0, '-screenHeight']
        ]
      }
    }
  },
  {
    selector: '.NftActivity.hunt .box',
    transforms: {
      scrollY: {
        translateY: [
          [
            `1*screenHeight+${introLength + placementLength}`,
            `2*screenHeight+${introLength + placementLength}-200`,
            `2*screenHeight+${introLength + placementLength + huntLength}`,
            `3*screenHeight+${introLength + placementLength + huntLength}-500`
          ],
          ['screenHeight+300', 0, 0, '-screenHeight']
        ]
      }
    }
  },
  {
    selector: '.NftActivity.hunt .Phone',
    transforms: {
      scrollY: {
        translateY: [
          [
            `1*screenHeight+${introLength + placementLength}`,
            `2*screenHeight+${introLength + placementLength}-50`,
            `2*screenHeight+${introLength + placementLength + huntLength}`,
            `3*screenHeight+${introLength + placementLength + huntLength}-50`
          ],
          ['screenHeight+300', 0, 0, '-screenHeight']
        ]
      }
    }
  },
  {
    selector: '.NftActivity.hunt .app-content',
    transforms: {
      scrollY: {
        translateY: [
          [
            `2*screenHeight+${introLength + placementLength}`,
            `2*screenHeight+${introLength + placementLength + huntLength}`
          ],
          ['elHeight', 0]
        ],
        translateX: [
          [0, totalLength],
          [0, 0]
        ]
      }
    }
  }
];

export default timeline;
