import i18next, { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const setDocumentLanguage = language => document.documentElement.setAttribute('lang', language);

use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    detection: {
      order: ['navigator']
    },
    resources: {
      en: {
        translations: require('./en.i18n.json')
      },
      de: {
        translations: require('./de.i18n.json')
      },
      es: {
        translations: require('./es.i18n.json')
      },
      sv: {
        translations: require('./sv.i18n.json')
      }
    },
    ns: ['translations'],
    defaultNS: 'translations'
  })
  .then(() => setDocumentLanguage(i18next.language.substr(0, 2)));

i18next.languages = ['en', 'de'];
